<template>
  <div class="page flex-col">
    <headerNav :initialSelectedIndex="currentSelectedIndex" @update:selectedIndex="updateSelectedIndex" />

    <div class="content flex-col">
      <div class="box_1 flex-row">
        <div class="box_12 flex-col">
          <span class="text_1">AI智能审图</span>
          <span class="paragraph_1">
            房地产企业竞争日益激烈的市场环境下，为提高企业的市场竞争力，顺应AI热潮，积极响应国企联合开发的号召，数字化管理是必然趋势，我司已经在多方面进行了数字化系统建设，整体上提高了公司管理效益和部门办公效率。在成本管理上如何实现降本增效是关乎企业生存和发展的重要课题，而成本管理是一项系统性工程，项目设计是成本管理的源头，在成本管理中起着的作用
            <br />
            随着AI、大数据、移动互联网等技术的快速发展，新技术的应用成为行业创新发展的主要武器，也是企业创新管理的重要法宝。本项目拟以“AI、大数据”为核心技术，建设一个“设计-成本”一体化平台，创新管理模式，为支撑企业整体战略发展搭建桥梁
          </span>
          <div class="text-wrapper_1 flex-col"  @click="linkto('/solution')"><span class="text_2" @click="linkto('/solution')">立即咨询</span></div>
        </div>
        <img class="image_1" referrerpolicy="no-referrer" src="./assets/img/148f3e5549dc6a2b2d2b6a041f2d0c1c.png" />
      </div>
      <div class="box_2 flex-col">
        <div class="text-group_2 flex-col justify-between">
          <span class="text_3">产品功能与优势</span>
          <span class="text_4">Product&nbsp;Features&nbsp;and&nbsp;Advantages</span>
        </div>
        <div class="group_6 flex-row justify-between">
          <div class="group_6_1 flex-col justify-between">

          <div class="section_7 flex-row">
          <div class="group_3 flex-col"></div>
          <div class="group_4 flex-col"></div>
          <div class="group_5 flex-col"></div>
        </div>
          
          <div class="group_1 flex-col"></div>
          </div>
          <div class="text-wrapper_6 flex-col justify-between">
            <span class="text_5">智能OCR识别</span>
            <span class="text_6">通过OCR技术识别CAD图纸的单独图层中包含的文字信息，并给出结构化解析结果</span>
          </div>
        </div>
        <img class="image_2" referrerpolicy="no-referrer" src="./assets/img/4d74e60c5c26b6ac2f29a48d808a7041.png" />
        <div class="group_7 flex-row justify-between">
          <div class="section_8 flex-row">
          <div class="box_3 flex-col"></div>
          <div class="box_4 flex-col"></div>
          <div class="box_5 flex-col"></div>
        </div>
          <div class="section_4 flex-col"></div>
          <div class="text-wrapper_7 flex-col justify-between">
            <span class="text_7">住宅区域轮廓线面积</span>
            <span class="text_8">
              通过AI识别技术，确定各个类型封闭曲线内的面积数值，并通过公式计算得到计容面积、不计容面积、建筑面积等相关指标
            </span>
          </div>
        </div>
        <img class="image_3" referrerpolicy="no-referrer" src="./assets/img/844314147f882dd3d31d6878c5ae1024.png" />
        <div class="group_8 flex-row justify-between">
          <div class="section_9 flex-row">
          <div class="box_6 flex-col"></div>
          <div class="box_7 flex-col"></div>
          <div class="box_8 flex-col"></div>
        </div>
          <div class="group_2 flex-col"></div>
          <div class="text-wrapper_8 flex-col justify-between">
            <span class="text_9">绿化面积</span>
            <span class="text_10">通过AI识别技术，获取商业建筑相关的面积指标计算</span>
          </div>
        </div>
        <img class="image_4" referrerpolicy="no-referrer" src="./assets/img/bae4f8f801ab7386745e77542f1ed45a.png" />
        <div class="group_9 flex-row justify-between">
          <div class="section_10 flex-row">
          <div class="box_9 flex-col"></div>
          <div class="box_10 flex-col"></div>
          <div class="box_11 flex-col"></div>
        </div>
          <div class="block_1 flex-col"></div>
          <div class="text-wrapper_9 flex-col justify-between">
            <span class="text_11">车位数量识别</span>
            <span class="text_12">通过模板抽取、模板归一化、模板形状匹配等AI技术，获得各型号车位的数据指标结果</span>
          </div>
        </div>
      </div>
      <bottomNav />
    </div>
  </div>
</template>
<script>
import headerNav from '../../../components/headerNav.vue'; // 导入 HeaderNav 组件
import bottomNav from '../../../components/bottomNav.vue'; // 导入 bottomNav 组件


export default {
  components: {
    headerNav,
    bottomNav
  },
  data() {
    return {
      currentSelectedIndex: 2 // 默认选中“新闻动态”
    };
  },
  methods: {
    updateSelectedIndex(index) {
      this.currentSelectedIndex = index; // 更新选中的索引
    },
    linkto(route) {

      this.$router.push(route);
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.css" />