<template>
  <div class="page flex-col">
    <headerNav
      :initialSelectedIndex="currentSelectedIndex"
      @update:selectedIndex="updateSelectedIndex"
    />
    <div class="content flex-col">
      <div class="box_2 flex-col">
        <div class="block_2 flex-col">
          <div class="box_3 flex-row">
            <div class="text-wrapper_1 flex-col justify-between">
              <span class="text_9">轻量级企业AI&nbsp;Agent平台</span>
              <span class="text_10"
                >“在垂直业务和协同办公领域，AI应用多向整合的企业大模型落地应用服务商”</span
              >
            </div>
            <img
              class="image_1"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng737c8f75554fc2f7d993d38949fcb67aa93abb81d39f025a6ef4f6c7ff25cdee.png"
            />
          </div>
          <div class="box_3_1 flex-row">
            <div class="block_15 flex-row">
              <div class="text-group_19 flex-col justify-between">
                <span class="text_50">联合开发，用户共创</span>
                <span class="paragraph_2">
                  产品的功能来自用户真实需求，细节的优化
                  <br />
                  源于与用户一起打磨共创。
                </span>
              </div>
            </div>
            <div class="block_16 flex-row">
              <div class="text-group_20 flex-col justify-between">
                <span class="text_51">十年沉淀，产业使命</span>
                <span class="text_52">
                  以“实现企业AI规模化应用“为宗旨，以“让AI员工服务每个组织和个人”为使命，十年筑匠心，打磨毫厘间！
                </span>
              </div>
            </div>
            <div class="block_17 flex-row">
              <div class="text-group_21 flex-col justify-between">
                <span class="text_53">初心不改，客户至上</span>
                <span class="text_54">
                  企业始终秉持初心之志，坚守“客户为本、科技利企”的发展理念，葆怀孺子之心，与客户一路相伴，携手同行！
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="block_3 flex-col">
          <video autoplay controls loop playsinline>
            <source
              src="https://koitechwgl-upload.obs.cn-east-3.myhuaweicloud.com:443/Project_guanwang/676_1726131531%28%E5%8E%9F%E8%A7%86%E9%A2%91%29.mp4?AccessKeyId=RD5QHGKHHHKTRLS2XMJP&Expires=1757725563&Signature=mCkzRup0ECFe7B7fgLfvPqUE4lg%3D"
              type="video/mp4"
            />
          </video>
        </div>

        <div class="block_5 flex-col">
          <div class="section_1 flex-row">
            <div class="text-group_1 flex-col justify-between">
              <span class="text_11">主营业务</span>
              <span class="text_12">Main&nbsp;business</span>
            </div>
          </div>
          <div class="section_2 flex-row justify-between">
            <div class="block_6 flex-col">
              <img
                class="image_2"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng34223ddfebbb9e03ed0501095d1122c734365e07bfdee248c00cc78bc5a92caf.png"
              />
              <span class="text_13">
                <!-- 上海麟鲤科技有限公司于2020年成立、由泛和集团投资，上海浦东城市数字化&nbsp;研究院、上海大学博士生导师担任咨询团队。 -->
                秉持着“ALL&nbsp;in&nbsp;AI”的公司战略，致&nbsp;力于搭建轻量级企业AI&nbsp;Agent&nbsp;MaaS平台，让AI模型走进企业”
              </span>
            </div>
            <div class="grid_1 flex-row">
              <div class="box_4 flex-col">
                <span class="text_14"
                  >基于BIM/数字孪生智慧科技行业空间矩阵</span
                >
                <div class="section_3 flex-row">
                  <div class="group_1 flex-col justify-between">
                    <div class="image-text_1 flex-row justify-between">
                      <div class="group_2 flex-col"></div>
                      <span
                        class="text-group_2"
                        @click="linkto('/products/bim')"
                        >BIM建模咨询</span
                      >
                    </div>
                    <div class="image-text_2 flex-row justify-between">
                      <div class="group_3 flex-col"></div>
                      <span class="text-group_3">智慧水务</span>
                    </div>
                  </div>
                  <div class="group_4 flex-col justify-between">
                    <div class="image-text_3 flex-row justify-between">
                      <div class="section_4 flex-col"></div>
                      <span
                        class="text-group_4"
                        @click="linkto('/products/bim')"
                        >数字孪生</span
                      >
                    </div>
                    <div class="image-text_4 flex-row justify-between">
                      <div class="block_7 flex-col"></div>
                      <span
                        class="text-group_5"
                        @click="linkto('/products/bim#shequ')"
                        >智慧社区</span
                      >
                    </div>
                  </div>
                  <div class="group_5 flex-row">
                    <div class="image-text_5 flex-row justify-between">
                      <div class="box_5 flex-col"></div>
                      <span
                        class="text-group_6"
                        @click="linkto('/products/bim#yuanqqu')"
                        >智慧园区</span
                      >
                    </div>
                    <div class="image-text_6 flex-row justify-between">
                      <div class="section_5 flex-col"></div>
                      <span
                        class="text-group_7"
                        @click="linkto('/products/bim#jianzao')"
                        >智慧建造</span
                      >
                    </div>
                  </div>
                </div>
                <div class="image-text_7 flex-row justify-between">
                  <div class="box_6 flex-col"></div>
                  <span
                    class="text-group_8"
                    @click="linkto('/products/bim#shangye')"
                    >数字商业</span
                  >
                </div>
              </div>
              <div class="box_7 flex-col">
                <div class="text-wrapper_2 flex-row">
                  <span class="text_15">大模型落地应用</span>
                </div>
                <div class="box_8 flex-row">
                  <div class="image-text_8 flex-row justify-between">
                    <div class="block_8 flex-col"></div>
                    <div class="text-group_9 flex-col justify-between">
                      <span
                        class="text_16"
                        @click="linkto('/products/ai_review')"
                        >AI智能审图</span
                      >
                      <span
                        class="text_17"
                        @click="linkto('/products/video_model')"
                        >麒麟视频大模型平台</span
                      >
                    </div>
                  </div>
                  <div class="block_9 flex-col"></div>

                  <div class="image-text_10 flex-row justify-between">
                    <div class="box_10 flex-col"></div>
                    <span
                      class="text-group_11"
                      @click="linkto('/products/enterprisetran_model')"
                    >
                      企业级大模型
                      <br />
                    </span>
                  </div>
                </div>
              </div>
              <div class="box_11 flex-col">
                <span class="text_18">QL&nbsp;Sky&nbsp;自动飞行系统</span>
                <div class="image-text_11 flex-row justify-between">
                  <div class="group_6 flex-col"></div>
                  <span class="text-group_12" @click="linkto('/products/drone')"
                    >无人值守7*24小时全自动巡逻巡检</span
                  >
                </div>
              </div>
              <div class="box_12 flex-col">
                <span class="text_19">数字监管</span>
                <div class="image-text_12 flex-row justify-between">
                  <div class="group_7 flex-col"></div>
                  <span class="text-group_13">指挥调度系统</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="block_5 flex-col">
          <div class="text-group_14 flex-col justify-between">
            <span class="text_20">成功案例</span>
            <span class="text_21">success&nbsp;cases</span>
          </div>
          <div class="block_10 flex-row justify-between">
            <div class="block_11 flex-col">
              <div class="image-text_13 flex-col justify-between">
                <div class="group_8 flex-col"></div>
                <div class="text-group_15 flex-col justify-between">
                  <span class="text_22">航运</span>
                  <span class="text_23">社交性质的境外船员派遣平台</span>
                </div>
              </div>
              <div class="group_9 flex-row justify-between">
                <span class="text_24">查看更多</span>
                <img
                  class="thumbnail_1"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPng02085523002831c28a44684e48e1f17c6e10b5132b6c0c09f23142fcf81582eb.png"
                />
              </div>
            </div>
            <div class="block_12 flex-col">
              <div class="image-text_14 flex-col justify-between">
                <div class="section_6 flex-col"></div>
                <div class="text-group_16 flex-col justify-between">
                  <span class="text_25">数字监管</span>
                  <span class="text_26">让一切都一目了然，尽在掌握之中</span>
                </div>
              </div>
              <div class="group_10 flex-row justify-between">
                <span class="text_27">查看更多</span>
                <img
                  class="thumbnail_2"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPng02085523002831c28a44684e48e1f17c6e10b5132b6c0c09f23142fcf81582eb.png"
                />
              </div>
            </div>
            <div class="block_13 flex-col">
              <div class="image-text_15 flex-col justify-between">
                <div class="group_11 flex-col"></div>
                <span class="text-group_17">轻量级企业AI&nbsp;&nbsp;Agent</span>
              </div>
              <div class="box_13 flex-row justify-between">
                <span class="text_28">查看更多</span>
                <img
                  class="thumbnail_3"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPng02085523002831c28a44684e48e1f17c6e10b5132b6c0c09f23142fcf81582eb.png"
                />
              </div>
              <span class="paragraph_1">
                以自研入库算法为核心，打造「安全合规」「高度定制」
                <br />
                的AI智能体
                <br />
              </span>
            </div>
          </div>
        </div>

        <bottomNav />
      </div>
    </div>
  </div>
</template>
<script>
import headerNav from "../../components/headerNav.vue"; // 导入 HeaderNav 组件
import bottomNav from "../../components/bottomNav.vue"; // 导入 bottomNav 组件

export default {
  components: {
    headerNav,
    bottomNav,
  },
  data() {
    return {
      currentSelectedIndex: 0, // 默认选中“新闻动态”
      activeIndex: 0, // 当前页index
    };
  },
  methods: {
    updateSelectedIndex(index) {
      this.currentSelectedIndex = index; // 更新选中的索引
    },
    linkto(route) {
      this.$router.push(route);
    },
  },
};
</script>
<style scoped lang="css" src="./assets/index.css" />
