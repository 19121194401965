<template>
  <div class="page flex-col">
    <headerNav :initialSelectedIndex="currentSelectedIndex" @update:selectedIndex="updateSelectedIndex" />

    <div class="content flex-col">
      <div class="group_1 flex-row">
        <div class="group_14 flex-col">
          <span class="text_1">麒麟视频大模型平台</span>
          <span class="paragraph_1">
            麒麟视频大模型平台综合利用多模态大模型技术、云计算技术、智能感知技术、大数据分析技术、数字孪生等技术手段。通过对多源数据(音视频图像、文本、网页、数据库等结构化与非结构化数据)的采集、处理和分析，实现对行为、事件的实时感知、分析和预警，帮助各行业用户识别潜在风险并采取相应的措施。方案广泛适用于重要行业信息化领域(党政公安、应急、交通、电力、水利、铁路等)、数字社会领域(城市大脑、数字乡村、公共服务、社会治理等)、企业领域(能源、矿山、工程等类企业)
            <br />
            综上所述，基于大模型的人工智能视觉平台解决方案旨在通过集成先进的视觉处理技术和大数据分析，为各行各业提供智能化、自动化的视觉应用服务，提升决策效率和响应速度，保障安全，优化资源配置
          </span>
          <div class="text-wrapper_1 flex-col" @click="linkto('/solution')"><span class="text_2"
              @click="linkto('/solution')">立即咨询</span></div>
        </div>
        <img class="image_1" referrerpolicy="no-referrer" src="./assets/img/f39ac6530f6156ee8081a78fffad221c.png" />
      </div>





      <div class="group_8 flex-col">

        <div class="text_group1 flex-col">
          <span class="text_3">产品功能与优势</span>
          <span class="text_4">Product&nbsp;Features&nbsp;and&nbsp;Advantages</span>
        </div>

        <div class="group_box flex-row">
          <div class="group_1_shequ_1 flex-col">
            <div class="group_box_shequ flex-col">
              <div class="group_234 flex-row">
                <div class="block_2 flex-col"></div>
                <div class="block_3 flex-col"></div>
                <div class="block_4 flex-col"></div>
              </div>
            </div>
            <div class="group_3 flex-col"></div>
          </div>

          <div class="text-group_6 flex-col justify-between">
            <span class="text_5">公安行业：城市级公安视大数据综合应用平台</span>
            <div class="text-wrapper_2">
              <span class="text_6">视频大数据综合应用平台基于</span>
              <span class="text_7">多模态大语言模型、视频全解析、大数据分析、云计算</span>
              <span class="text_8">
                等技术，并结合公安实战业务需求，为用户提供海量视频全解析、多维感知数据清洗汇聚、视图数据级联、视频多维研判于一体的视频大数据业务实战系统，全面提高公安机关事件精准处置、犯罪精准打击的能力，实现案事件快速处置、侦破的目标。方案适用于省、市城市级公安科信部门及各专业警种
              </span>
            </div>
          </div>

        </div>
        <div>

          <img class="image_4" referrerpolicy="no-referrer" src="./assets/img/f294579f4e5b2785960a00a5a9d19fe5.png" />
        </div>

        <div class="group_box flex-row">
          <div class="group_1_shequ_1 flex-col">
            <div class="group_box_shequ flex-col">
              <div class="group_234 flex-row">
                <div class="block_2 flex-col"></div>
                <div class="block_3 flex-col"></div>
                <div class="block_4 flex-col"></div>
              </div>
            </div>
            <div class="group_4 flex-col"></div>
          </div>

          <div class="text-group_6 flex-col justify-between">
            <span class="text_5">交通行业：城市治理态势感知平台</span>
            <div class="text-wrapper_2">
              <span class="text_6">综合利用</span>
              <span class="text_7">多模态大语言模型技术、智能感知技术、大数据分析和人工智能</span>
              <span class="text_8">
                等技术手段，通过对多源数据的采集、处理和分析，实现对交通违法行为、事件的实时感知、分析和预警，帮助用户识别潜在交通风险并采取相应的措施。方案适用于城市级交通、公安交警、高速公路管理部门 </span>
            </div>
          </div>

        </div>
        <div>

          <img class="image_4" referrerpolicy="no-referrer" src="./assets/img/f294579f4e5b2785960a00a5a9d19fe5.png" />
        </div>


        <div class="group_box flex-row">
          <div class="group_1_shequ_1 flex-col">
            <div class="group_box_shequ flex-col">
              <div class="group_234 flex-row">
                <div class="block_2 flex-col"></div>
                <div class="block_3 flex-col"></div>
                <div class="block_4 flex-col"></div>
              </div>
            </div>
            <div class="group_5 flex-col"></div>
          </div>

          <div class="text-group_6 flex-col justify-between">
            <span class="text_5">应急行业：应急事件态势感知平台</span>
            <div class="text-wrapper_2">
              <span class="text_8">
                综合利用多模态大语言模型技术、智能感知技术、大数据分析和人工智能等技术手段。通过对多源数据的采集、处理和分析，实现对应急领域风险行为、事件的实时感知、分析和预警，更好推进应急领域安全监管数字化转型。方案适用于部、省市级应急管理部门及应急安全生产相关企业
              </span>
            </div>
          </div>

        </div>
        <div>

          <img class="image_4" referrerpolicy="no-referrer" src="./assets/img/f294579f4e5b2785960a00a5a9d19fe5.png" />
        </div>

        <div class="group_box flex-row">
          <div class="group_1_shequ_1 flex-col">
            <div class="group_box_shequ flex-col">
              <div class="group_234 flex-row">
                <div class="block_2 flex-col"></div>
                <div class="block_3 flex-col"></div>
                <div class="block_4 flex-col"></div>
              </div>
            </div>
            <div class="group_6 flex-col"></div>
          </div>

          <div class="text-group_6 flex-col justify-between">
            <span class="text_5">城市治理：城市治理态势感知平台</span>
            <div class="text-wrapper_2">
              <span class="text_6">综合利用</span>
              <span class="text_7">多模态大语言模型技术、智能感知技术、大数据分析和人工智能</span>
              <span class="text_8">
                等技术手段，将基于原有数字城管的技术、机制等，向基于AI人工智能的智慧城管进行拓展升级。以此实现看得清、管得住、执法准、会思考的全面智能化目标在整个智慧城市的建设。方案适用于城市大数据部门、城市综合管理部门
              </span>
            </div>
          </div>

        </div>
        <div>

          <img class="image_4" referrerpolicy="no-referrer" src="./assets/img/f294579f4e5b2785960a00a5a9d19fe5.png" />
        </div>

        <div class="group_box flex-row">
          <div class="group_1_shequ_1 flex-col">
            <div class="group_box_shequ flex-col">
              <div class="group_234 flex-row">
                <div class="block_2 flex-col"></div>
                <div class="block_3 flex-col"></div>
                <div class="block_4 flex-col"></div>
              </div>
            </div>
            <div class="group_4 flex-col"></div>
          </div>

          <div class="text-group_6 flex-col justify-between">
            <span class="text_5">铁路行业：铁路运行态势感知平台</span>
            <div class="text-wrapper_2">
              <span class="text_6">综合利用</span>
              <span class="text_7">多模态大语言模型技术、智能感知技术、大数据分析和人工智能</span>
              <span class="text_8">
                等技术手段。通过对多源数据的采集、处理和分析，实现对司乘人员违规行为、道路风险的实时感知、分析和预警，帮助铁路领域用户识别潜在风险并采取相应的措施。方案适用于
                各级铁路路局管理部门
              </span>
            </div>
          </div>

        </div>
        <div>
        </div>


      </div>

      <bottomNav />
    </div>
  </div>
</template>
<script>
import headerNav from '../../../components/headerNav.vue'; // 导入 HeaderNav 组件
import bottomNav from '../../../components/bottomNav.vue'; // 导入 bottomNav 组件


export default {
  components: {
    headerNav,
    bottomNav
  },
  data() {
    return {
      currentSelectedIndex: 2 // 默认选中“新闻动态”
    };
  },
  methods: {
    updateSelectedIndex(index) {
      this.currentSelectedIndex = index; // 更新选中的索引
    },
    linkto(route) {

      this.$router.push(route);
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.css" />