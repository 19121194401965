<template>
  <div class="page flex-col">
    <headerNav :initialSelectedIndex="currentSelectedIndex" @update:selectedIndex="updateSelectedIndex" />

    <div class="content flex-col">
      <div class="box_1 flex-row">
        <div class="group_14 flex-col">
          <span class="text_1">RPA数字员工</span>
          <span class="paragraph_1">
            我们致力于为所有规模的企业打开数字员工的大门&nbsp;，提供量身定制的RPA数字员工。让AI技术成为您业务增长的催化剂，推动工作效率的提升，优化业务流程，让您在竞争中占得先机
            <br />
            我们使办公自动化和智能化，能模拟人的各种操作，在任何应用程式上进行鼠标点击、键盘输入、读取信息等操作，释放人的逻辑规则、重复性的工作；同时从生成产品营销文案到投放计划，AI都能让您的工作更加轻松高效。更重要的是，我们的模型能够根据您的工作习惯和需求，真正做到提供企业级别个性化的办公服务，让生产力有更多的时间去关注业务的核心部分
          </span>
          <div class="text-wrapper_1 flex-col" @click="linkto('/solution')"><span class="text_2"
              @click="linkto('/solution')">立即咨询</span></div>
        </div>
        <img class="image_1" referrerpolicy="no-referrer" src="./assets/img/14a246f1a7bac093e799eecaa3fdaa8a.png" />
      </div>
      <div class="box_3 flex-col">
        <div class="text-group_9 flex-col justify-between">
          <span class="text_3">产品功能与优势</span>
          <span class="text_4">Product&nbsp;Features&nbsp;and&nbsp;Advantages</span>
        </div>
        <div class="box_11 flex-row">
          <div class="group_box_1 flex-col">
            <div class="box_1_1 flex-col" v-show="activeGroup1 === 1"></div>
            <div class="box_1_2 flex-col" v-show="activeGroup1 === 2"></div>
            <div class="box_1_3 flex-col" v-show="activeGroup1 === 3"></div>
          </div>
          <div class="group_15 flex-col">
            <div class="group_2 flex-col"
              :style="{ backgroundColor: activeGroup1 === 1 ? 'rgba(140, 0, 255, 1)' : 'rgba(245, 242, 249, 1)' }"
              
              ></div>
            <div class="group_3 flex-col"
              :style="{ backgroundColor: activeGroup1 === 2 ? 'rgba(140, 0, 255, 1)' : 'rgba(245, 242, 249, 1)' }"></div>
            <div class="group_4 flex-col"
              :style="{ backgroundColor: activeGroup1 === 3 ? 'rgba(140, 0, 255, 1)' : 'rgba(245, 242, 249, 1)' }"></div>


          </div>
          <div class="group_16 flex-col">
            <span class="text_5">RPA数字员工应用场景</span>
            <div class="section_1 flex-row" 
            :style="{ backgroundColor: activeGroup1 === 1 ? 'rgba(246, 246, 246, 1)' : 'rgba(255, 255, 255, 1)' }"
            @click="setActiveGroup1(1)"
            
            >
              <div class="image-text_8 flex-row justify-between">
                <img class="label_1" referrerpolicy="no-referrer"
                src="./assets/img/24e9136905887c0070f1e180b40de0ea.png" />
                <span class="text-group_2"
                  :style="{ color: activeGroup1 === 1 ? 'rgba(140, 0, 255, 1)' : 'rgba(110, 110, 110, 1)' }"
                  @click="setActiveGroup1(1)">人事/行政</span>
              </div>
            </div>
            <div class="section_1 flex-row" 
            :style="{ backgroundColor: activeGroup1 === 2 ? 'rgba(246, 246, 246, 1)' : 'rgba(255, 255, 255, 1)' }"
            @click="setActiveGroup1(2)">
            <div class="image-text_9 flex-row justify-between" @click="setActiveGroup1(2)">
              <img class="label_2" referrerpolicy="no-referrer"
                src="./assets/img/24e9136905887c0070f1e180b40de0ea.png" />
              <span class="text-group_3"
                :style="{ color: activeGroup1 === 2 ? 'rgba(140, 0, 255, 1)' : 'rgba(110, 110, 110, 1)' }"
                @click="setActiveGroup1(2)">财务</span>
            </div>
            </div>
            <div class="section_1 flex-row" 
            :style="{ backgroundColor: activeGroup1 === 3 ? 'rgba(246, 246, 246, 1)' : 'rgba(255, 255, 255, 1)' }"
            @click="setActiveGroup1(3)">
            <div class="image-text_10 flex-row justify-between" @click="setActiveGroup1(3)">
              <img class="label_3" referrerpolicy="no-referrer"
                src="./assets/img/9c70a9df70f09e9e247aa5e14970586a.png" />
              <span class="text-group_4"
                :style="{ color: activeGroup1 === 3 ? 'rgba(140, 0, 255, 1)' : 'rgba(110, 110, 110, 1)' }"
                @click="setActiveGroup1(3)">供应链</span>
            </div>
            </div>
          </div>
        </div>
        <img class="image_2" referrerpolicy="no-referrer" src="./assets/img/d1a012979536356279e4537f9c1e3b91.png" />
        <div class="box_12 flex-row justify-between">
          <div class="group_6 flex-col"></div>
          <span class="text_6">实现任意程序的自动化运行</span>
        </div>
        <img class="image_3" referrerpolicy="no-referrer" src="./assets/img/26942332d40aa94ba26b480daffe1f55.png" />
        <div class="box_13 flex-row">
          <div class="block_1_1 flex-col" v-show="activeGroup2 === 1"></div>
          <div class="block_1_2 flex-col" v-show="activeGroup2 === 2"></div>
          <div class="block_1_3 flex-col" v-show="activeGroup2 === 3"></div>
          <div class="block_1_4 flex-col" v-show="activeGroup2 === 4"></div>



          <div class="block_11 flex-col">
            <div class="block_3 flex-col"
            :style="{ backgroundColor: activeGroup2 === 1 ? 'rgba(140, 0, 255, 1)' : 'rgba(245, 242, 249, 1)' }"

            ></div>
            <div class="block_4 flex-col"
               :style="{ backgroundColor: activeGroup2 === 2 ? 'rgba(140, 0, 255, 1)' : 'rgba(245, 242, 249, 1)' }"
            ></div>
            <div class="block_5 flex-col"
               :style="{ backgroundColor: activeGroup2 === 3 ? 'rgba(140, 0, 255, 1)' : 'rgba(245, 242, 249, 1)' }"
            ></div>
            <div class="block_6 flex-col"
               :style="{ backgroundColor: activeGroup2 === 4 ? 'rgba(140, 0, 255, 1)' : 'rgba(245, 242, 249, 1)' }"
            ></div>
          </div>
          <div class="block_12 flex-col">
            <span class="text_7">联动市场营销工具</span>
            <div class="box_7 flex-row"
              :style="{ backgroundColor: activeGroup2 === 1 ? 'rgba(246, 246, 246, 1)' : 'rgba(255, 255, 255, 1)' }"
            @click="setActiveGroup2(1)"
            >
              <div class="image-text_11 flex-row justify-between">
                <img class="label_4" referrerpolicy="no-referrer"
                src="./assets/img/6dd6cc2893ee17bd92388745f78ddb19.png" />
                <span class="text-group_5"   @click="setActiveGroup2(1)"
                  :style="{ color: activeGroup2 === 1 ? 'rgba(140, 0, 255, 1)' : 'rgba(110, 110, 110, 1)' }"
                
                >创造服装设计思路</span>
              </div>
            </div>
            <div class="box_7 flex-row"
              :style="{ backgroundColor: activeGroup2 === 2 ? 'rgba(246, 246, 246, 1)' : 'rgba(255, 255, 255, 1)' }"
            @click="setActiveGroup2(2)"
            >
            <div class="image-text_12 flex-row justify-between">
              <img class="label_5" referrerpolicy="no-referrer"
                src="./assets/img/6dd6cc2893ee17bd92388745f78ddb19.png" />
              <span class="text-group_6"  @click="setActiveGroup2(2)"
              :style="{ color: activeGroup2 === 2 ? 'rgba(140, 0, 255, 1)' : 'rgba(110, 110, 110, 1)' }"

              >生成设计稿</span>
            </div>
            </div>
            <div class="box_7 flex-row"
              :style="{ backgroundColor: activeGroup2 === 3 ? 'rgba(246, 246, 246, 1)' : 'rgba(255, 255, 255, 1)' }"
            @click="setActiveGroup2(3)"
            >
            <div class="image-text_13 flex-row justify-between">
              <img class="label_6" referrerpolicy="no-referrer"
                src="./assets/img/c2e898fcdd4cba4ca7ac3072279c7bda.png" />
              <span class="text-group_7"   @click="setActiveGroup2(3)" 
              :style="{ color: activeGroup2 === 3 ? 'rgba(140, 0, 255, 1)' : 'rgba(110, 110, 110, 1)' }"

              >生成上身图</span>
            </div>
            </div>

            <div class="box_7 flex-row"
              :style="{ backgroundColor: activeGroup2 === 4 ? 'rgba(246, 246, 246, 1)' : 'rgba(255, 255, 255, 1)' }"
            @click="setActiveGroup2(4)"
            >
            <div class="image-text_14 flex-row justify-between">
              <img class="label_7" referrerpolicy="no-referrer"
                src="./assets/img/048ff4683b483a8329415174ad002424.png" />
              <span class="text-group_8"   @click="setActiveGroup2(4)"
              
              :style="{ color: activeGroup2 === 4 ? 'rgba(140, 0, 255, 1)' : 'rgba(110, 110, 110, 1)' }"

              >创作营销广告文案</span>
            </div>
            </div>
          </div>
        </div>
        <div class="group_8 flex-row">
          <div class="box_8 flex-col"></div>
          <div class="box_9 flex-col"></div>
          <div class="box_10 flex-col"></div>
        </div>
        <div class="group_9 flex-row">
          <div class="group_10 flex-col"></div>
          <div class="group_11 flex-col"></div>
          <div class="group_12 flex-col"></div>
        </div>
        <div class="group_13 flex-row">
          <div class="block_8 flex-col"></div>
          <div class="block_9 flex-col"></div>
          <div class="block_10 flex-col"></div>
        </div>
      </div>
      <bottomNav />
    </div>
  </div>
</template>
<script>
import headerNav from '../../../components/headerNav.vue'; // 导入 HeaderNav 组件
import bottomNav from '../../../components/bottomNav.vue'; // 导入 bottomNav 组件


export default {
  components: {
    headerNav,
    bottomNav
  },
  data() {
    return {
      currentSelectedIndex: 2 // 默认选中“新闻动态”
      , activeGroup1: 1 // 设置当前的激活组
      ,activeGroup2:1
    };
  },
  methods: {
    updateSelectedIndex(index) {
      this.currentSelectedIndex = index; // 更新选中的索引
    },
    setActiveGroup1(groupNumber) {
      this.activeGroup1 = groupNumber; // 修正拼写错误，设置当前的激活组

    },
    setActiveGroup2(groupNumber) {
      this.activeGroup2 = groupNumber; // 修正拼写错误，设置当前的激活组

    },
    linkto(route) {

      this.$router.push(route);
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.css" />