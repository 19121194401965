<template>
  <div class="page flex-col">
    <headerNav
      :initialSelectedIndex="currentSelectedIndex"
      @update:selectedIndex="updateSelectedIndex"
    />

    <div class="content flex-col">
      <div class="group_1 flex-row">
        <div class="group_4 flex-col justify-between">
          <span class="text_1">麟鲤科技无人机辅助飞行平台</span>
          <span class="text_2">
            麟鲤科技聚焦低空经济和遥感大数据产业的战略机遇，专注于开发安全、智能、自主可控的低空无人机遥感平台。结合多功能机载采集器和麒麟大模型算法，提供面向城市治理、设备巡检和应急服务等领域的解决方案，实现空地联动、云边协同的数字化创新
          </span>
          <div class="text-wrapper_1 flex-col" @click="linkto('/solution')">
            <span class="text_3" @click="linkto('/solution')">立即咨询</span>
          </div>
        </div>
        <img
          class="image_1"
          referrerpolicy="no-referrer"
          src="./assets/img/3e4a8aedff91c1e9e2ca9ee73106fe72.png"
        />
      </div>
      <div class="group_2 flex-col">
        <div class="text-group_2 flex-col justify-between">
          <span class="text_4">产品功能与优势</span>
          <span class="text_5"
            >Product&nbsp;Features&nbsp;and&nbsp;Advantages</span
          >
        </div>
        <span class="paragraph_1">
          麟鲤科技无人机辅助飞行平台以无人机综合管理为核心，推动区域遥感网络的高效建设。平台不仅提供无人机及无人值守系统的全方位调度与远程控制，还支持全国及区域无人机网络的遥感应用，并助力低空航路网络的规划与构建，同时为民航系统提供云交换平台接入服务
          <br />
          用户只需通过网页登录，即可在直观且丰富的可视化界面中轻松完成航线规划、设备实时监控、无人机集群调度、数据上传与管理，以及分析与应用等操作流程
          <br />
          平台的主要功能包括：智能化的航线设计、灵活的任务调度、多维的数据管理、场景化的算法支持、业务闭环管理，以及开放的生态系统
        </span>
        <div class="group_box_shequ flex-col">
          <div class="group_8 flex-row">
            <div class="block_2 flex-col"></div>
            <div class="block_3 flex-col"></div>
            <div class="block_4 flex-col"></div>
          </div>
        </div>
        <div class="group_3 flex-col"></div>

        <span class="text_6">三大核心创新</span>
        <span class="paragraph_2">
          1.&nbsp;工单式管理：通过工单维度对巡查业务进行全流程管理，从任务创建到数据采集与分析，实现一站式操作。工单化管理不仅大幅降低沟通成本，还便于统计运营效果，确保业务的高效闭环。
          <br />
          2.&nbsp;网格化巡查：采用网格化管理模式，自动生成最优巡查航线，并实现海量巡查数据的精准管理，显著提升基层管理的效率和精度。
          <br />
          3.&nbsp;场景化AI分析：&nbsp;&nbsp;针对城管、交通、水务、国土、环保和应急等领域，提供定制化的场景化AI分析，助力城市信息化管理迈向智能化的新高度
        </span>
      </div>
      <!-- <div class="image-wrapper_3 flex-col">
      <img
        class="image_5"
        referrerpolicy="no-referrer"
        src="./assets/img/f2975342824d6eaed49fa08981a2e60b.png"
      />
    </div>
    <img
      class="image_6"
      referrerpolicy="no-referrer"
      src="./assets/img/83806078ff6497d3bc67707b053f5920.png"
    /> -->
      <bottomNav />
    </div>
  </div>
</template>
<script>
import headerNav from "../../../components/headerNav.vue"; // 导入 HeaderNav 组件
import bottomNav from "../../../components/bottomNav.vue"; // 导入 bottomNav 组件

export default {
  components: {
    headerNav,
    bottomNav,
  },
  data() {
    return {
      currentSelectedIndex: 2, // 默认选中“新闻动态”
    };
  },
  methods: {
    updateSelectedIndex(index) {
      this.currentSelectedIndex = index; // 更新选中的索引
    },
    linkto(route) {
      this.$router.push(route);
    },
  },
};
</script>
<style scoped lang="css" src="./assets/index.css" />
