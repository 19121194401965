<template>
  <div class="page flex-col">
    <div class="box_1 flex-col">
      <img
        class="image_7"
        referrerpolicy="no-referrer"
        src="./assets/img/740533697c50463d2ddb9a4856a0a15f.png"
      />
      <div class="box_5 flex-row justify-between">
        <div class="text-wrapper_3 flex-col justify-between">
          <span class="text_1">智能时代&nbsp;·实力科技</span>
          <span class="paragraph_1">
            Intelligent&nbsp;Era&nbsp;
            <br />
            Strength&nbsp;Technology
            <br />
          </span>
        </div>
        <div class="image-wrapper_1 flex-col justify-center align-center">
          <img
            class="image_2"
            referrerpolicy="no-referrer"
            src="./assets/img/806f62c20f1fce2d52fb5fa1ce500499.png"
          />
        </div>
      </div>
    </div>
    <div class="box_6 flex-col">
      <div class="text-wrapper_2">
        <span class="text_2">——————</span>
        <span class="text_3">&nbsp;&nbsp;成功案例&nbsp;</span>
        <span class="text_4">——————</span>
      </div>
      <div class="group_11 flex-row justify-between">
        <div class="group_2 flex-col">
          <div class="block_1 flex-row">
            <div class="section_2 flex-col"></div>
            <div class="section_3 flex-col"></div>
            <div class="section_4 flex-col"></div>
          </div>
        </div>
        <div class="box_7 flex-col">
          <span class="text_5">航运</span>
          <span class="text_6">社交性质的境外船员派遣平台</span>
          <div class="section_5 flex-row">
            <div class="image-text_13 flex-row justify-between">
              <img
                class="label_1"
                referrerpolicy="no-referrer"
                src="./assets/img/68b08d0762ad600b62d3046cd3c8d441.png"
              />
              <span class="text-group_1"
                >生成三色证件照（红底、蓝底、白底）</span
              >
            </div>
          </div>
          <div class="image-text_14 flex-row justify-between">
            <img
              class="label_2"
              referrerpolicy="no-referrer"
              src="./assets/img/54e9838c1d153c5a5be0564e36bd5971.png"
            />
            <span class="text-group_2">生成中国古风艺术照</span>
          </div>
          <div class="image-text_15 flex-row justify-between">
            <img
              class="label_3"
              referrerpolicy="no-referrer"
              src="./assets/img/a816f8776b438efd0ecd24e54567fdc5.png"
            />
            <span class="text-group_3">编辑照片（一键美颜、面部年龄调整）</span>
          </div>
        </div>
      </div>
      <img
        class="image_3"
        referrerpolicy="no-referrer"
        src="./assets/img/a3f2bdd1f32f36ed9b72fc9477b5f0b4.png"
      />
      <div class="group_12 flex-row justify-between">
        <div class="group_4 flex-col">
          <div class="group_5 flex-row">
            <div class="block_2 flex-col"></div>
            <div class="block_3 flex-col"></div>
            <div class="block_4 flex-col"></div>
          </div>
        </div>
        <div class="section_8 flex-col">
          <span class="text_7">数字监管</span>
          <span class="text_8">让一切都一目了然，尽在掌握之中!</span>
          <div class="image-text_16 flex-row justify-between">
            <img
              class="label_4"
              referrerpolicy="no-referrer"
              src="./assets/img/4a0cd6c80f5b5630337f7304c90060d0.png"
            />
            <span class="text-group_4">项目介绍</span>
          </div>
          <div class="image-text_17 flex-row justify-between">
            <img
              class="label_5"
              referrerpolicy="no-referrer"
              src="./assets/img/ffa05c22f85af8e81c2494dcaf121a07.png"
            />
            <span class="text-group_5">进度跟踪</span>
          </div>
          <div class="image-text_18 flex-row justify-between">
            <img
              class="label_6"
              referrerpolicy="no-referrer"
              src="./assets/img/d6e1f6cf1efb5aa8c31a1def8fb9c110.png"
            />
            <span class="text-group_6">视频监控</span>
          </div>
          <div class="image-text_19 flex-row justify-between">
            <img
              class="label_7"
              referrerpolicy="no-referrer"
              src="./assets/img/735967a4d31d7b9d12c8fc90c28fb201.png"
            />
            <span class="text-group_7">任务调度</span>
          </div>
          <div class="image-text_20 flex-row justify-between">
            <img
              class="label_8"
              referrerpolicy="no-referrer"
              src="./assets/img/d6e2a078ad516fc3a1a2501c08ecb5c1.png"
            />
            <span class="text-group_8">视频会议</span>
          </div>
        </div>
      </div>
      <img
        class="image_4"
        referrerpolicy="no-referrer"
        src="./assets/img/696b3e47023701fa33c81e0837ca4edd.png"
      />
      <div class="group_13 flex-row justify-between">
        <div class="box_3 flex-col">
          <div class="group_7 flex-row">
            <div class="group_8 flex-col"></div>
            <div class="group_9 flex-col"></div>
            <div class="group_10 flex-col"></div>
          </div>
        </div>
        <div class="group_14 flex-col">
          <span class="text_9">轻量级企业AI&nbsp;Agent</span>
          <span class="paragraph_2">
            在垂直业务和协同办公领域，AI应用多向整合的企业
            <br />
            智能化方案，助力企业服务向数智化、多模态转型。
          </span>
          <div class="image-text_21 flex-row justify-between">
            <img
              class="label_9"
              referrerpolicy="no-referrer"
              src="./assets/img/bff765b97e02179e77aa411e5b11aae6.png"
            />
            <span class="text-group_9">人人能用</span>
          </div>
          <div class="image-text_22 flex-row justify-between">
            <img
              class="label_10"
              referrerpolicy="no-referrer"
              src="./assets/img/5209afda8013dbc87af5f3341070db84.png"
            />
            <span class="text-group_10">易用性</span>
          </div>
          <div class="image-text_23 flex-row justify-between">
            <img
              class="label_11"
              referrerpolicy="no-referrer"
              src="./assets/img/7aa4228fed90aac7aa850585900e2b82.png"
            />
            <span class="text-group_11">无需求偏差</span>
          </div>
          <div class="image-text_24 flex-row justify-between">
            <img
              class="label_12"
              referrerpolicy="no-referrer"
              src="./assets/img/aea09bae90984a0e328314cf5ffa3b7c.png"
            />
            <span class="text-group_12">经济性</span>
          </div>
        </div>
      </div>
      <img
        class="image_5"
        referrerpolicy="no-referrer"
        src="./assets/img/c5e4827b7e362606efdcf2dbafa268cb.png"
      />
      <img
        class="image_6"
        referrerpolicy="no-referrer"
        src="./assets/img/d8629560f8de50592d7d39385a4635e2.png"
      />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {},
    };
  },
  methods: {},
};
</script>
<style scoped lang="css" src="./assets/index.css" />
