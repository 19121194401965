<template>
  <div class="page flex-col">
    <headerNav :initialSelectedIndex="currentSelectedIndex" @update:selectedIndex="updateSelectedIndex"/>
      
      <div class="content flex-col">
    <div class="group_1 flex-col">
      <div class="section_1 flex-row">
        <div class="section_2 flex-col justify-between">
          <span class="text_2">麟鲤科技&nbsp;AI赋能无限美好</span>
          <span class="text_1">Linli&nbsp;Technology&nbsp;&nbsp;&nbsp;&nbsp;AI&nbsp;Empowers&nbsp;Infinite</span>

        </div>
        <!-- <img
          class="image_1"
          referrerpolicy="no-referrer"
          src="./assets/img/d61f6abb6a79a01f7a1f95cec8388aab.png"
        />
        <img
          class="image_2"
          referrerpolicy="no-referrer"
          src="./assets/img/03c74db70d5db962d26cb801aa80f421.png"
        /> -->
      </div>
    </div>
    <div class="box_2 flex-row">
      <img
        class="image_3"
        referrerpolicy="no-referrer"
        src="./assets/img/7c7d84ffbc2cc3ba5963234289c2b469.png"
      />
      <span class="text_3">&nbsp;关于我们</span>
      <img
        class="image_4"
        referrerpolicy="no-referrer"
        src="./assets/img/35d5396e6ee14b3e2104147fd787e562.png"
      />
    </div>
    <div class="box_3 flex-row justify-between">
      <div class="block_2 flex-col">
        <!-- <div class="block_3 flex-row">
          <span class="text_4">02</span>
          <img
            class="thumbnail_1"
            referrerpolicy="no-referrer"
            src="./assets/img/8463fe110e3bc4dcaa0f75d2c471489f.png"
          />
          <span class="text_5">05</span>
        </div> -->
      </div>
      <div class="group_12 flex-col">
        <span class="text_6">公司简介</span>
        <span class="text_7">Introduce</span>
        <span class="text_8">
          <!-- 上海麟鲤科技有限公司于2020年成立、由泛和集团投资，上海浦东城市数字化&nbsp;研究院、上海大学博士生导师担任咨询团队。 -->
          秉持着“ALL&nbsp;in&nbsp;AI”的公司战略，致&nbsp;力于搭建轻量级企业AI&nbsp;Agent&nbsp;MaaS平台，让AI模型走进企业
        </span>
        <!-- <div class="box_4 flex-row justify-between">
          <div class="image-wrapper_1 flex-col">
            <img
              class="thumbnail_2"
              referrerpolicy="no-referrer"
              src="./assets/img/6da243bb5245f1f42a5355028d90a05b.png"
            />
          </div>
          <div class="image-wrapper_2 flex-col">
            <img
              class="thumbnail_3"
              referrerpolicy="no-referrer"
              src="./assets/img/0cbd9edee2c6712a51dcd0b87ccdb5e7.png"
            />
          </div>
        </div> -->
      </div>
    </div>
    <div class="group_6 flex-col">
      <span class="text_9">咨询电话&nbsp;&nbsp;联系地址</span>
      <img
        class="image_5"
        referrerpolicy="no-referrer"
        src="./assets/img/58b2b1087bfbd7ef56d8939e3ddc0616.png"
      />
      <img
        class="image_6"
        referrerpolicy="no-referrer"
        src="./assets/img/024860a4935239e0693d5b194a1841d2.png"
      />
      <span class="text_10">CONTACT&nbsp;&nbsp;US</span>
      <div class="block_5 flex-row">
        <div class="group_7 flex-col">
          <div class="group_8 flex-col"><div class="block_6 flex-col"></div></div>
        </div>
        <div class="group_9 flex-row justify-center">
          <div class="image-text_2 flex-row justify-between">
            <div class="group_10 flex-col"></div>
            <div class="text-group_2 flex-col justify-between">
              <span class="text_11">上海麟鲤科技有限公司</span>
              <div class="text-wrapper_1">
                <span class="text_12">咨询电话：18721233045</span>
                <br/>
                <span class="text_13"> 地址1:上海市杨浦区大连路970号海上海新城2105</span>
                <br/>
                <span class="text_13">地址2:上海市浦东新区书院镇外灶17-18组路666号</span>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <bottomNav/> 
  </div>
</template>
<script>
import headerNav from '../../components/headerNav.vue'; // 导入 HeaderNav 组件
import bottomNav from '../../components/bottomNav.vue'; // 导入 bottomNav 组件


export default {
  components: {
    headerNav,
    bottomNav
  },
  data() {
    return {
      currentSelectedIndex: 2 // 默认选中“新闻动态”
    };
  },
  methods: {
    updateSelectedIndex(index) {
      this.currentSelectedIndex = index; // 更新选中的索引
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.css" />