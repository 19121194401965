<template>
  <div class="page flex-col">
    <div class="group_1 flex-row">
      <div class="group_2 flex-col"></div>
      <span class="text_1">鳞鲤科技</span>
      <span
        v-for="(text, index) in texts"
        :key="index"
        :class="{
          'top_text_8': hoveredIndex === index || selectedIndex === index,
          'top_text_7': hoveredIndex !== index && selectedIndex !== index
        }"
        @mouseover="hoveredIndex = index"
        @mouseleave="hoveredIndex = -1"
        @click="handlePrimaryClick(index)"
        class="span-text"
        :ref="'menu-' + index"
      >
        {{ text }}
      </span>
      
      <!-- 二级菜单显示在 "产品中心" 下方 -->
      <div v-if="showSubMenu" class="submenu" :style="submenuStyle">
        <span
          v-for="(submenu, subIndex) in subMenus"
          :key="subIndex"
          :class="{
            'top_text_8': hoveredSubIndex === subIndex || selectedSubIndex === subIndex,
            'top_text_7': hoveredSubIndex !== subIndex && selectedSubIndex !== subIndex
          }"
          @click="navigateToSubMenu(subIndex)"
          @mouseover="hoveredSubIndex = subIndex"
          @mouseleave="hoveredSubIndex = -1"
          class="submenu-text"
        >
          {{ submenu }}
        </span>
      </div>
    </div>
    <div class="group_3 flex-col">
      <div class="box_1 flex-col">
        <div class="text-wrapper_1 flex-col">
          <span class="text_9">实际出发&nbsp;核心呈现</span>
          <span class="paragraph_1">
            基于bim/数字孪生+智慧科技行业空间矩阵，AI+全息工作台，
            <br />
            智慧航运，数字监管，四大业务并驾齐驱。
            <br />
          </span>
        </div>
      </div>
      <div class="box_2 flex-col"></div>
      <div class="box_3 flex-row justify-between">
        <div class="text-wrapper_2 flex-col">
          <span class="text_10">关于麟鲤科技</span>
          <span class="paragraph_2">
            我们致力于为所有规模的企业打开AI大模型的大门&nbsp;，提供量身定制的轻量级企业AI&nbsp;&nbsp;Agent。让AI技术成为您业务增长的催化剂，推动工作效率的提升，优化业务流程，让您在竞争中占得先机。
            <br />
          </span>
          <span class="text_11">30000+</span>
          <span class="text_12">客户规模</span>
          <span class="text_13">拥有</span>
        </div>
        <div class="box_4 flex-col"><div class="group_4 flex-col"></div></div>
      </div>
      <div class="box_5 flex-col"></div>
      <div class="text-group_1 flex-col justify-between">
        <span class="text_14">核心价值</span>
        <span class="text_15">Core&nbsp;values</span>
      </div>
      <div class="box_6 flex-col">
        <div class="box_7 flex-row justify-between">
          <div class="image-text_1 flex-row justify-between">
            <div class="box_8 flex-col"></div>
            <span class="text-group_2">轻量级企业AI&nbsp;Agent</span>
          </div>
          <div class="group_5 flex-row">
            <span class="text_16">立即使用</span>
            <img
              class="thumbnail_1"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng41ff5827c1489221350960014a1cbcd304020bf3fa7560c61c6c2287ff41c572.png"
            />
          </div>
        </div>
        <span class="paragraph_3">
          我们致力于为所有规模的企业打开AI大模型的大门&nbsp;，提供量身定制的轻量级企业AI&nbsp;&nbsp;Agent。让AI技术成为您业务增长的催化剂，推动工作效率的提升，优化业务流程，让您
          <br />
          在竞争中占得先机。
          <br />
        </span>
        <div class="list_1 flex-row">
          <div class="text-group_3 flex-col" v-for="(item, index) in loopData0" :key="index">
            <span class="text_17" v-html="item.lanhutext0"></span>
            <span class="text_18" v-html="item.lanhutext1"></span>
          </div>
        </div>
      </div>
      <div class="box_9 flex-row">
        <div class="group_6 flex-col">
          <div class="text-group_4 flex-col justify-between">
            <span class="text_19">BIM技术与数字孪生PaaS平台</span>
            <span class="text_20">基于BIM/数字孪生+智慧科技行业空间矩阵</span>
          </div>
          <div class="image-text_2 flex-row justify-between">
            <span class="text-group_5">查看更多</span>
            <img
              class="thumbnail_2"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng7dcb2b69ea19cfd0fcd4c3a8aeeb1cecd6536a065fc867aeec1b489b55d1c836.png"
            />
          </div>
        </div>
        <div class="group_7 flex-col">
          <span class="text_21">AI+全息工作台</span>
          <span class="paragraph_4">
            数据跨境咨询服务、
            <br />
            AI视频识别系统
          </span>
          <div class="image-text_3 flex-row justify-between">
            <span class="text-group_6">查看更多</span>
            <img
              class="thumbnail_3"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng7dcb2b69ea19cfd0fcd4c3a8aeeb1cecd6536a065fc867aeec1b489b55d1c836.png"
            />
          </div>
        </div>
        <div class="group_8 flex-col">
          <span class="text_22">航运</span>
          <span class="paragraph_5">
            境外船员劳务派遣平台、
            <br />
            航运大模型
          </span>
          <div class="image-text_4 flex-row justify-between">
            <span class="text-group_7">查看更多</span>
            <img
              class="thumbnail_4"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng7dcb2b69ea19cfd0fcd4c3a8aeeb1cecd6536a065fc867aeec1b489b55d1c836.png"
            />
          </div>
        </div>
        <div class="group_9 flex-col">
          <span class="text_23">数字监管</span>
          <div class="image-text_5 flex-row">
            <div class="text-group_8 flex-col">
              <span class="text_24">查看更多</span>
              <span class="paragraph_6">
                可视化调度系统、
                <br />
                城市生命线监管工程系统
                <br />
              </span>
            </div>
            <img
              class="thumbnail_5"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng7dcb2b69ea19cfd0fcd4c3a8aeeb1cecd6536a065fc867aeec1b489b55d1c836.png"
            />
          </div>
        </div>
      </div>
      <div class="box_10 flex-row justify-between">
        <img
          class="label_1"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPnge6fdd14785aeb82b537d8069ee0e965a7657b52dce04bac8bba9b3f47950a2d5.png"
        />
        <div class="image-wrapper_1 flex-col">
          <img
            class="label_2"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng99a7c0824f90345ebb151c18f81c9eef4979012932ee2cdae171ed79169a6e58.png"
          />
        </div>
      </div>
      <div class="box_11 flex-col"></div>
      <div class="text-group_9 flex-col justify-between">
        <span class="text_25">成功案例</span>
        <span class="text_26">success&nbsp;cases</span>
      </div>
      <div class="box_12 flex-row justify-between">
        <div class="block_1 flex-col">
          <div class="image-text_6 flex-col justify-between">
            <div class="box_13 flex-col"></div>
            <div class="text-group_10 flex-col justify-between">
              <span class="text_27">航运</span>
              <span class="text_28">社交性质的境外船员派遣平台</span>
            </div>
          </div>
          <div class="block_2 flex-row justify-between">
            <span class="text_29">查看更多</span>
            <img
              class="thumbnail_6"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng02085523002831c28a44684e48e1f17c6e10b5132b6c0c09f23142fcf81582eb.png"
            />
          </div>
        </div>
        <div class="block_3 flex-col">
          <div class="image-text_7 flex-col justify-between">
            <div class="section_1 flex-col"></div>
            <div class="text-group_11 flex-col justify-between">
              <span class="text_30">数字监管</span>
              <span class="text_31">让一切都一目了然，尽在掌握之中</span>
            </div>
          </div>
          <div class="section_2 flex-row justify-between">
            <span class="text_32">查看更多</span>
            <img
              class="thumbnail_7"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng02085523002831c28a44684e48e1f17c6e10b5132b6c0c09f23142fcf81582eb.png"
            />
          </div>
        </div>
        <div class="block_4 flex-col">
          <div class="image-text_8 flex-col justify-between">
            <div class="group_10 flex-col"></div>
            <span class="text-group_12">轻量级企业AI&nbsp;&nbsp;Agent</span>
          </div>
          <div class="group_11 flex-row justify-between">
            <span class="text_33">查看更多</span>
            <img
              class="thumbnail_8"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng02085523002831c28a44684e48e1f17c6e10b5132b6c0c09f23142fcf81582eb.png"
            />
          </div>
          <span class="paragraph_7">
            以自研入库算法为核心，打造「安全合规」「高度定制」
            <br />
            的AI智能体
            <br />
          </span>
        </div>
      </div>
      <div class="box_14 flex-row">
        <div class="text-wrapper_3 flex-col justify-between">
          <span class="text_34">用户服务</span>
          <span class="text_35">用户服务干线</span>
          <span class="text_36">用户中心</span>
        </div>
        <div class="text-wrapper_4 flex-col justify-between">
          <span class="text_37">关于公司</span>
          <span class="text_38">集团介绍</span>
          <span class="text_39">新闻资讯</span>
        </div>
        <div class="text-wrapper_5 flex-col justify-between">
          <span class="text_40">加入公司</span>
          <span class="text_41">社会招聘</span>
          <span class="text_42">校园招聘</span>
        </div>
        <div class="text-wrapper_6 flex-col justify-between">
          <span class="text_43">关注公司</span>
          <span class="text_44">官方微信</span>
          <span class="text_45">新浪微博</span>
        </div>
        <div class="text-group_13 flex-col justify-between">
          <span class="text_46">其他</span>
          <span class="text_47">联系方式：138-8888-8888</span>
          <span class="text_48">微信：22222222</span>
        </div>
      </div>
      <div class="text-wrapper_7 flex-row">
        <span class="text_49">生态伙伴入住</span>
        <span class="text_50">联系我们</span>
        <span class="text_51">实习生招聘</span>
        <span class="text_52">Linkedin</span>
      </div>
      <span class="text_53">投资者关系</span>
      <span class="text_54">Copyright&nbsp;&#64;2022&nbsp;xxxxxxxxxxxxxx&nbsp;All&nbsp;Ringts&nbsp;Reserved</span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      hoveredIndex: -1, // 当前悬停的索引
      selectedIndex: 1, // 默认选中“新闻动态”
      hoveredSubIndex: -1, // 当前悬停的二级菜单索引
      selectedSubIndex: -1, // 当前选中的二级菜单索引
      showSubMenu: false, // 控制二级菜单的显示
      texts: [
        "首页",
        "行业应用",
        "产品中心",
        "成功案例",
        "免费定制方案",
        "关于我们",
        "新闻动态"
      ],
      routes: [
        "/",
        "/industry",
        "/lanhu_aizhinengshentu",
        "/lanhu_qiyejidamoxing",
        "/lanhu_wurenjipingtai",
        "/lanhu_shuziyuangong",
        "/news"
      ],
      subMenus: [
        "BIM+数字孪生",
        "无人机",
        "RPA数字员工",
        "麒麟视频大模型平台",
        "企业级大模型",
        "AI智能审图"
      ],
      subMenuRoutes: [
        "/products/bim",
        "/products/drone",
        "/products/rpa",
        "/products/video-model",
        "/products/enterprise-model",
        "/products/ai-review"
      ]
    };
  },
  methods: {
    handlePrimaryClick(index) {
      this.selectedIndex = index;
      if (index === 2) { // "产品中心"
        const targetElement = this.$refs['menu-' + index][0];
        const rect = targetElement.getBoundingClientRect();
        this.submenuStyle = {
          top: `${rect.bottom + window.scrollY}px`,
          left: `${rect.left + window.scrollX}px`
        };
        this.showSubMenu = !this.showSubMenu; // 切换二级菜单的显示状态
      } else {
        this.showSubMenu = false;
        this.$router.push(this.routes[index]); // 跳转到主菜单的其他页面
      }
    },
    navigateToSubMenu(subIndex) {
      this.selectedSubIndex = subIndex; // 更新选中的二级菜单索引
      this.$router.push(this.subMenuRoutes[subIndex]); // 跳转到二级菜单的页面
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.css" />