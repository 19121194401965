<template>
  <div class="page flex-col">
    <headerNav
      :initialSelectedIndex="currentSelectedIndex"
      @update:selectedIndex="updateSelectedIndex"
    />

    <div class="content flex-col">
      <div class="group_1 flex-row">
        <div class="text-wrapper_6 flex-col justify-between">
          <span class="text_1">麟鲤科技&nbsp;AI赋能无限美好</span>
          <span class="text_2"
            >Linli&nbsp;Technology&nbsp;&nbsp;&nbsp;&nbsp;AI&nbsp;Empowers&nbsp;Infinite</span
          >
        </div>

        <!-- <div class="group_2 flex-col">
          <div class="image-wrapper_1 flex-col">
          </div>
        </div>

        <img
          class="image_2"
          referrerpolicy="no-referrer"
          src="./assets/img/273daf64c788d5ec40ffb101c4cd3a0a.png"
        /> -->
      </div>
      <!-- <img
        class="image_3"
        referrerpolicy="no-referrer"
        src="./assets/img/d16fb6b2d7a3161e58dd31476f6fc561.png"
      /> -->
     

      <div class="group_3 flex-col">
        
        <span class="text_5">电话</span>
        <div class="text-wrapper_2 flex-col">
          <input
            class="input-field"
            v-model="phone"
            type="text"
            placeholder="请输入"
          />
        </div>
        <span class="text_7">您的需求</span>
        <div class="text-wrapper_3 flex-col">
          <input
            class="input-field"
            v-model="description"
            type="text"
            placeholder="请输入"
          />
        </div>
        <span class="text_10">姓名</span>
        <div class="text-wrapper_5 flex-col">
          <input
            class="input-field"
            v-model="name"
            type="text"
            placeholder="请输入"
          />
        </div>
        <div
          class="text-wrapper_4 flex-col"
          @click="handleSubmit"
          :disabled="isSubmitting"
        >
          <button class="text_9">提交</button>
        </div>
      </div>
    </div>
    <bottomNav />
  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";

import headerNav from "../../components/headerNav.vue"; // 导入 HeaderNav 组件
import bottomNav from "../../components/bottomNav.vue"; // 导入 bottomNav 组件

export default {
  components: {
    headerNav,
    bottomNav,
  },
  data() {
    return {
      currentSelectedIndex: 4, // 默认选中“新闻动态”
      phone: "",
      description: "",
      name: "",
      isSubmitting: false,
      errorMessage: "",
      successMessage: "",
    };
  },
  methods: {
    updateSelectedIndex(index) {
      this.currentSelectedIndex = index; // 更新选中的索引
    },
    validateInput() {
      if (!this.name || !this.phone) {
        Swal.fire("错误", "姓名和电话不能为空！", "error");
        return false;
      }
      const phoneRegex = /^[0-9]+$/; // 只允许数字
      if (!phoneRegex.test(this.phone)) {
        Swal.fire("错误", "电话必须是数字！", "error");
        return false;
      }
      const illegalChars = /[^a-zA-Z0-9\u4e00-\u9fa5]/g; // 只允许中英文和数字
      if (
        illegalChars.test(this.phone) ||
        illegalChars.test(this.description) ||
        illegalChars.test(this.name)
      ) {
        Swal.fire("错误", "输入包含非法字符！", "error");
        return false;
      }
      return true;
    },
    handleSubmit() {
      if (!this.validateInput()) return;

      if (this.isSubmitting) {
        Swal.fire("提示", "请等待 30 秒后再次提交", "info");
        return;
      }

      this.isSubmitting = true;
      Swal.fire({
        title: "提交中...",
        text: "请稍等",
        didOpen: () => {
          Swal.showLoading();
        },
      });

      this.errorMessage = "";
      this.successMessage = "";

      // 向服务器发送请求
      axios
        .post(
          "http://124.71.28.146:3000/api/leads/create",
          {
            name: this.name,
            description: this.description,
            phone: this.phone,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          Swal.fire("成功", "提交成功", "success");
          this.resetForm();
        })
        .catch(() => {
          Swal.fire("失败", "提交失败，请重试", "error");
        })
        .finally(() => {
          // 30秒后可以再次提交
          setTimeout(() => {
            this.isSubmitting = false;
          }, 30000);
        });
    },
    resetForm() {
      this.name = "";
      this.description = "";
      this.phone = "";
    },
  },
};
</script>
<style scoped lang="css" src="./assets/index.css" />
