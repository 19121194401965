<template>
  <div class="page flex-col">
    <headerNav :initialSelectedIndex="currentSelectedIndex" @update:selectedIndex="updateSelectedIndex"/>
      
      <div class="content flex-col">
    <div class="group_1 flex-row">
      <div class="group_13 flex-col">
        <span class="text_1">面向未来，AI启航&nbsp;——&nbsp;企业级大模型</span>
        <span class="text_2">
          在这个充满机遇、挑战的社会，每一个决策都可能决定着未来的走向。麟鲤科技企业级大模型助力企业时刻引领科技前沿，深邃洞察全行业，不仅能够帮助企业把握现在，更能预见并塑造未来
        </span>
        <div class="text-wrapper_1 flex-col"  @click="linkto('/solution')"><span class="text_3"  @click="linkto('/solution')">立即咨询</span></div>
      </div>
      <div>
      <img
        class="image_1"
        referrerpolicy="no-referrer"
        src="./assets/img/4e27f21e73a559278b5d7b37765ea3f0.png"
      />
    </div>
    </div>
    <div class="group_3 flex-col">
      <div class="text-group_2 flex-col justify-between">
        <span class="text_4">产品功能与优势</span>
        <span class="text_5">Product&nbsp;Features&nbsp;and&nbsp;Advantages</span>
      </div>
      <div class="text-wrapper_2">
        <span class="text_6">智能预测：</span>
        <span class="paragraph_1">
          利用先进的机器学习算法，对海量数据进行深度挖掘，为企业提供精确的市场趋势分析与预测，让您的决策始终快人一步。
          <br />
        </span>
        <span class="text_7">个性化推荐：</span>
        <span class="paragraph_2">
          基于用户行为习惯和偏好，为客户提供高度个性化的体验和服务，从而增强客户忠诚度和满意度。
          <br />
        </span>
        <span class="text_8">自动化工作流：</span>
        <span class="paragraph_3">
          通过自动化处理日常任务，减少人力成本的同时提高工作效率，让员工专注于更有价值的工作。
          <br />
        </span>
        <span class="text_9">创新思维：</span>
        <span class="paragraph_4">
          借助人工智能的力量，激发团队的创造力，探索新的商业模式和技术应用，推动企业不断进化。
          <br />
        </span>
        <span class="text_10">加密技术：</span>
        <span class="paragraph_5">
          采用最新的加密技术，确保所有数据传输过程中的安全性，让您无忧使用。
          <br />
        </span>
        <span class="text_11">隐私保护：</span>
        <span class="paragraph_6">
          遵循严格的隐私政策和法律法规要求，确保用户信息得到妥善管理和保护。
          <br />
        </span>
        <span class="text_12">云端支持：</span>
        <span class="paragraph_7">
          支持云端部署，轻松扩展资源，适应不同规模的企业需求。
          <br />
        </span>
        <span class="text_13">无缝集成：</span>
        <span class="paragraph_8">
          提供开放的API接口，方便与其他系统和服务快速集成，实现业务流程的无缝衔接。
          <br />
        </span>
        <span class="text_14">定制方案：</span>
        <span class="paragraph_9">
          根据您的特定需求，提供量身定制的服务计划，确保解决方案与您的业务完美契合。
          <br />
        </span>
        <span class="text_15">技术支持：</span>
        <span class="paragraph_10">
          拥有专业的技术支持团队，随时准备为您提供咨询和支持，保证您的系统始终处于最佳状态。
          <br />
          在这个数字化转型的浪潮中，稳步前行，抵达胜利的彼岸
        </span>
      </div>
      <img
        class="image_2"
        referrerpolicy="no-referrer"
        src="./assets/img/db3347fb8e6ec0a5ceac12c42db0f052.png"
      />
      <!-- <div class="group_14 flex-row justify-between">
       
       
        <div class="box_1 flex-col"></div>
        <span class="text_16">开箱即用的企业大模型应用平台</span>
      </div>
      <img
        class="image_3"
        referrerpolicy="no-referrer"
        src="./assets/img/7aea396b6c79bdd0fbca09de4e53b2f7.png"
      /> -->
      <!-- <div class="group_15 flex-row justify-between">
        <div class="group_4 flex-col"></div>
        <span class="text_17">企业大模型落地“三板斧”</span>
      </div>
      <img
        class="image_4"
        referrerpolicy="no-referrer"
        src="./assets/img/fd5f914bcf44805019188e79253ef33d.png"
      /> -->
      <div class="group_16 flex-row justify-between">
        <div class="group_5 flex-col"></div>
        <span class="text_18">“麟渊千里”智能体：千里之行，始于足下，高效工作从此开始</span>
      </div>
      <img
        class="image_5"
        referrerpolicy="no-referrer"
        src="./assets/img/ab0e9b62c959da068d2efc224ee9d79a.png"
      />
      <div class="group_17 flex-row justify-between">
        <div class="section_5 flex-col"></div>
        <span class="text_19">文件剖析大师：为您深度剖析文档细节，帮助员工更加清晰全面的了解文档内容</span>
      </div>
      <img
        class="image_6"
        referrerpolicy="no-referrer"
        src="./assets/img/3ec00b47e887b1c00cc5c81e11215c8a.png"
      />
      <div class="group_18 flex-row justify-between">
        <div class="group_6 flex-col"></div>
        <span class="text_20">图像全方位感知：360°全方位深度分析图片内容</span>
      </div>
      <div class="section_7 flex-row">
        <div class="box_2 flex-col"></div>
        <div class="box_3 flex-col"></div>
        <div class="box_4 flex-col"></div>
      </div>
      <div class="section_8 flex-row">
        <div class="block_1 flex-col"></div>
        <div class="block_2 flex-col"></div>
        <div class="block_3 flex-col"></div>
      </div>
      <div class="section_9 flex-row">
        <div class="group_7 flex-col"></div>
        <div class="group_8 flex-col"></div>
        <div class="group_9 flex-col"></div>
      </div>
      <!-- <div class="section_10 flex-row">
        <div class="group_10 flex-col"></div>
        <div class="group_11 flex-col"></div>
        <div class="group_12 flex-col"></div>
      </div>
      <div class="section_11 flex-row">
        <div class="box_5 flex-col"></div>
        <div class="box_6 flex-col"></div>
        <div class="box_7 flex-col"></div>
      </div> -->
    </div>
         
    <bottomNav/> 
  </div>
  </div>
</template>
<script>
import headerNav from '../../../components/headerNav.vue'; // 导入 HeaderNav 组件
import bottomNav from '../../../components/bottomNav.vue'; // 导入 bottomNav 组件


export default {
  components: {
    headerNav,
    bottomNav
  },
  data() {
    return {
      currentSelectedIndex: 2 // 默认选中“新闻动态”
    };
  },
  methods: {
    updateSelectedIndex(index) {
      this.currentSelectedIndex = index; // 更新选中的索引
    },
    linkto(route) {

      this.$router.push(route);
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.css" />