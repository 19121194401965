<template>
  <div class="page flex-col">
    <headerNav :initialSelectedIndex="currentSelectedIndex" @update:selectedIndex="updateSelectedIndex" />

    <div class="content flex-col">
      <div class="box_2 flex-col">
        <div class="box_3 flex-row justify-between">
          <span class="text_9">新闻动态</span>
          <img class="image_1" referrerpolicy="no-referrer"
            src="./assets/img/SketchPng9e1a2cb3a182f30d530ffc5513d1dfb64b59882f44e91958744b04c3aeab0a3b.png" />
        </div>

        <div class="box_4 flex-row">
          <span class="text_10">news&nbsp;information</span>
          <img class="image_2" referrerpolicy="no-referrer"
            src="./assets/img/SketchPngfa77dfe3a27b34ab0fe1ba7ab5dbbb513e31394b862d03ba6f6f9a407daa7ea4.png" />
          <img class="image_3" referrerpolicy="no-referrer"
            src="./assets/img/SketchPng957dd1dc6eddd674b0817d79707b8ebba9c1e962c1170837444e7843d4f9a593.png" />
        </div>
        <img class="image_4" referrerpolicy="no-referrer"
          src="./assets/img/SketchPnga772c7513e43688a65463086833268af393acee7e6b2335678e893a567d177f8.png" />
      </div>

      <div class="content">
        <div v-for="(item, index) in paginatedData" :key="index">
          <div class="box_12 flex-row justify-between">
            <div class="box_13 flex-col">
              <div class="box_14 flex-col" :class= "item.class">
              </div>

            </div>
            <div class="text-wrapper_4 flex-col">
              <a :href="item.link" target="_blank" class="text_17">{{ item.title }}</a>
              <span class="text_18">{{ item.description }}</span>
            </div>
          </div>
          <!-- <img v-for="(item, index) in paginatedData" :key="index" class="image" :src="item.imgSrc" referrerpolicy="no-referrer" /> -->
          <img class="image_5" referrerpolicy="no-referrer"
            src="./assets/img/SketchPngdcf71805bdbbb6d7337639c13ace88b7ad6568eed71c07bbecde7da79242c96d.png" />
        </div>
      </div>

      <!-- <div class="pagination">
        <button class="image-wrapper_2"   @click="prevPage" :disabled="currentPage === 1">&lt;</button>
        <span> {{ currentPage }} </span>
        <button class="image-wrapper_2" @click="nextPage" :disabled="currentPage === totalPages">&gt;</button>
      </div> -->
       <div class="box_4 flex-row justify-between">
          <div class="image-wrapper_2 flex-col"  @click="prevPage" :disabled="currentPage === 1">
            <img
              class="thumbnail"
              referrerpolicy="no-referrer"
              src="./assets/img/6da243bb5245f1f42a5355028d90a05b.png"
             
            />
          </div>
          <span class="text_page"> {{ currentPage }} </span>
          <div class="image-wrapper_2 flex-col"  @click="nextPage" :disabled="currentPage === totalPages">
            <img
              class="thumbnail"
              referrerpolicy="no-referrer"
              src="./assets/img/0cbd9edee2c6712a51dcd0b87ccdb5e7.png"
             
            />
          </div>
        </div>
    </div>
    <bottomNav />
  </div>
</template>
<script>
import headerNav from "../../components/headerNav.vue"; // 导入 HeaderNav 组件
import bottomNav from "../../components/bottomNav.vue"; // 导入 bottomNav 组件

export default {
  components: {
    headerNav,
    bottomNav,
  },
  data() {
    return {
      currentSelectedIndex: 6, // 默认选中“新闻动态”

      currentPage: 1,
      pageSize: 4, // 每页显示的条数
      articles: [
      
        {
          title: '泛和集团董事长江四元携麟鲤科技一行拜访吉林省地方海事局，共绘信息化监管新蓝图',
          description: '2024年9月11日，泛和集团董事长江四元携麟鲤科技一行拜访吉林省地方海事局，双方就“科技赋能+智能监管”新蓝图展开交流和讨论，旨在提升水上交通的安全管理水平，也为广大船民和航运企业提供更加便捷的服务。省地方海事局副局长吉福新、省地方海事局曹家瑞出席会议。',
          link: 'https://mp.weixin.qq.com/s/GiVKnLRBnX1Kyo052bOEzw',
           class:"news6"
        },
        {
          title: '数字赋能，智引未来，学习头部国企数字化转型实践经验——泛和集团代表团赴河北钢铁拜访交流',
          description: '泛和集团董事长江四元带领麟鲤科技团队9月9日至9月10日拜访河钢集团有限公司。期间，江四元董事长一行拜会了河钢集团党委书记、董事长于勇，河钢集团副总经理、河钢数字董事长李毅仁，河钢数字党委书记、总经理申培，实地考察了河钢研发中心、河钢数字技术股份有限公司。',
          link: 'https://mp.weixin.qq.com/s/rUO_lnCW4qhv5fuk8RD04A',
          class:"news1"
        },
        {
          title: '麟鲤科技×羚牛新能源 | 探索企业级大模型在新能源领域的落地应用',
          description: '2024年8月29日，麟鲤科技团队一行来到嘉兴长三角氢能创新中心，与羚牛新能源科技（上海）有限公司就企业级大模型如何赋能新能源产业展开交流。羚牛新能源CEO蒲红霞携部分公司骨干出席会议。',
          link: 'https://mp.weixin.qq.com/s/AAddxIFfDLJ6tB-6DTZ_oA',
            class:"news2"
        },
        { title: '麟鲤科技携手东古智能，共绘低空经济“无人机＋产业”新蓝图', 
        description: '2024年8月23日，麟鲤科技团队一行来到伟士789产业园4F，参观了东古智能自研的以“C919、低空物流平台DGI-S600”为代表的一系列载人、物流运输的无人机系列。参观结束后，于东古智能会议室，麟鲤科技技术团队、北大临港科创中心杨老师等人一起针对低空经济“无人机＋产业”的新蓝图展开合作讨论。', 
        link: 'https://mp.weixin.qq.com/s/F9L48xOZ_Kkt5OmGsHIP9A',
        class:"news3" },
        { title: '最新资讯 | 麟鲤科技为临港生态增添新活力',
         description: '临港——离市区很远，离世界很近，制度化的创新吸引了很多优秀企业，以更加开放的姿态拥抱世界。临港新片区是最年轻的一个区域，从曾经上海东部的一片沿海滩涂，到如今的上海经济发展的“增长极”和“发动机”，演绎着填海造陆的雄伟史诗，从无到有的“临港速度”。', 
          link: 'https://mp.weixin.qq.com/s/V5kUpkBs_FP_vppRGNNUiA' ,
          class:"news4"
        },
        { title: '上海麟鲤科技有限公司临港分公司开业典礼暨书院镇战略合作协议签约', description: 
        '5月24号下午四点，作为泛和集团数字化板块的上海麟鲤科技有限公司临港分公司在临港新区书院镇举行开业仪式。湖北省人民政府驻上海办事处经济协作处处长靳中卿；上海市浦东新区书院镇党委委员副镇长沈华;湖北省恩施州建始县人大常委会党组副书记、县人大常委会副主任张华；上海市湖北商会会长张国雄;上海市湖北商会执行会长、泛和集团董事长江四元;上海市浦东新区书院镇外灶村党总支书记汪敏等领导共同出席并剪彩，见证上海麟鲤科技有限公司临港分公司的成立', 
        link: 'https://mp.weixin.qq.com/s/F9L48xOZ_Kkt5OmGsHIP9A' ,
       class:"news5"
      }
      ]
    };
  },
  methods: {
    updateSelectedIndex(index) {
      this.currentSelectedIndex = index; // 更新选中的索引
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    }
  },

  computed: {
    totalPages() {
      return Math.ceil(this.articles.length / this.pageSize);
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.pageSize;
      return this.articles.slice(start, start + this.pageSize);
    }
  },
};
</script>
<style scoped lang="css" src="./assets/index.css" />
