import { render, staticRenderFns } from "./bottomNav.vue?vue&type=template&id=1b9f8e60&scoped=true"
var script = {}
import style0 from "../assets/bottom.css?vue&type=style&index=0&id=1b9f8e60&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b9f8e60",
  null
  
)

export default component.exports