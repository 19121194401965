import Vue from 'vue'
import VueRouter from 'vue-router'
import lanhu_chenggonganlikaobei2 from '../views/lanhu_chenggonganlikaobei2/index.vue'
// 产品矩阵
//AI智能审图
import ai_review from '../views/products/ai_review/index.vue'
//企业级大模型
import enterprisetran_model from '../views/products/enterprisetran_model/index.vue'
//无人机
import drone from '../views/products/drone/index.vue'
//RPA数字员工
import rpa from '../views/products/rpa/index.vue'
//麒麟视频大模型平台
import video_model from '../views/products/video_model/index.vue'
//BIM+数字孪生
import bim from '../views/products/bim/index.vue'
import about from '../views/about/index.vue'
import industry from '../views/industry/index.vue'
import home from '../views/home/index.vue'
import solution from '../views/solution/index.vue'
import news from '../views/news/index.vue'
import cases from '../views/cases/index.vue'

Vue.use(VueRouter)

const routes = [
    {
    path: '/lanhu_chenggonganlikaobei2',
    redirect: "/lanhu_chenggonganlikaobei2"
  },
  {
    path: '/lanhu_chenggonganlikaobei2',
    name: 'lanhu_chenggonganlikaobei2',
    component: lanhu_chenggonganlikaobei2
  },
  {
    path: '/products/ai_review',
    name: 'ai_review',
    meta:{title:"AI智能审图"},
    component: ai_review
  },
  {
    path: '/products/enterprisetran_model',
    name: 'enterprisetran_model',
    meta:{title:"企业级大模型"},
    component: enterprisetran_model
  },
  {
    path: '/products/drone',
    name: 'drone',
    meta:{title:"无人机"},
    component: drone
  },
  {
    path: '/products/rpa',
    name: 'rpa',
    meta:{title:"RPA数字员工"},
    component: rpa
  },
  {
    path: '/products/video_model',
    name: 'video_model',
    meta:{title:"麒麟视频大模型平台"},
    component: video_model
  },
  {
    path: '/products/bim',
    name: 'bim',
    meta:{title:"BIM+数字孪生"},
    component: bim
  },
  {
    path: '/about',
    name: 'about',
    meta:{title:"关于我们"},
    component: about
  },
  {
    path: '/industry',
    name: 'industry',
    meta:{title:"行业应用"},
    component: industry
  },
  {
    path: '/',
    name: 'home',
    meta:{title:"上海麟鲤科技"},
    component: home
  },
  {
    path: '/solution',
    name: 'solution',
    meta:{title:"免费定制方案"},
    component: solution
  },
  {
    path: '/news',
    name: 'news',
    meta:{title:"新闻动态"},
    component: news
  },
  {
    path: '/cases',
    name: 'cases',
    meta:{title:"成功案例"},
    component: cases
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to,from,next)=>{//beforeEach是router的钩子函数，在进入路由前执行
  if(to.meta.title){//判断是否有标题
      document.title = to.meta.title
  }
  next()  //执行进入路由，如果不写就不会进入目标页
})

router.afterEach((to)=>{
  if (to.hash) {
    const target = document.querySelector(to.hash);
    if (target) {
      target.scrollIntoView({ behavior: 'smooth' });
    }
  }else{
    window,scrollTo(0,0)
  }
  
})

// router.scrollTo((to, from, savedPosition)=>{
//   if (to.hash) {
//     return { selector: to.hash };
//   } else if (savedPosition) {
//     return savedPosition;ß
//   } else {
//     return { x: 0, y: 0 };
//   }
// })




export default router
