<template>
  <div class="page flex-col">
    <div class="group_1 flex-col">
      <img
        class="image_8"
        referrerpolicy="no-referrer"
        src="./assets/img/c16ea5d11cdf9408fddc63ee10e67068.png"
      />
      <div class="group_32 flex-row justify-between">
        <div class="text-wrapper_31 flex-col justify-between">
          <span class="text_1">智能时代&nbsp;·实力科技</span>
          <span class="paragraph_1">
            Intelligent&nbsp;Era&nbsp;
            <br />
            Strength&nbsp;Technology
            <br />
          </span>
        </div>
        <div class="image-wrapper_1 flex-col justify-center align-center">
          <img
            class="image_2"
            referrerpolicy="no-referrer"
            src="./assets/img/55c3ecca72e70f385d6a400eab904346.png"
          />
        </div>
      </div>
    </div>
    <div class="box_19 flex-col">
      <div class="group_33 flex-row">
        <div class="text-wrapper_2">
          <span class="text_2">——————</span>
          <span class="text_3">&nbsp;&nbsp;成功案例&nbsp;</span>
          <span class="text_4">——————</span>
        </div>
      </div>
      <div class="group_34 flex-row">
        <span class="text_5">全部</span>
        <div class="text-wrapper_3 flex-col"><span class="text_6">最新</span></div>
        <span class="text_7">效率工具</span>
        <span class="text_8">商业服务</span>
        <span class="text_9">文本创造</span>
        <span class="text_10">代码助手</span>
        <span class="text_11">生活方式</span>
      </div>
      <div class="group_35 flex-row justify-between">
        <div class="section_1 flex-col">
          <div class="box_20 flex-row justify-between">
            <div class="image-wrapper_2 flex-col">
              <img
                class="image_3"
                referrerpolicy="no-referrer"
                src="./assets/img/11456a49c69f240be01a860ba7008056.png"
              />
            </div>
            <div class="text-wrapper_32 flex-col justify-between">
              <span class="text_12">思维模型专家</span>
              <span class="text_13">
                您好，我是精通各种思维模型的专家，请输入您的问题，我会告诉你应该用什么模型，以及这个模型的定义、用法、案例~
              </span>
            </div>
          </div>
          <div class="text-wrapper_33 flex-row"><span class="text_14">用户3337843567711&#64;liuxingliang</span></div>
          <div class="box_21 flex-row">
            <div class="group_6 flex-row">
              <span class="text_15">立即使用</span>
              <img
                class="thumbnail_1"
                referrerpolicy="no-referrer"
                src="./assets/img/50d6301a67600be966ac68d927fea141.png"
              />
            </div>
          </div>
        </div>
        <div class="section_2 flex-col">
          <div class="group_36 flex-row justify-between">
            <div class="image-wrapper_3 flex-col">
              <img
                class="image_4"
                referrerpolicy="no-referrer"
                src="./assets/img/1f7ce78b037b5b168db8e6935063f8ca.png"
              />
            </div>
            <div class="text-wrapper_34 flex-col justify-between">
              <span class="text_16">思维模型专家</span>
              <span class="text_17">
                您好，我是精通各种思维模型的专家，请输入您的问题，我会告诉你应该用什么模型，以及这个模型的定义、用法、案例~
              </span>
            </div>
          </div>
          <div class="text-wrapper_35 flex-row"><span class="text_18">用户3337843567711&#64;liuxingliang</span></div>
          <div class="group_37 flex-row">
            <div class="section_3 flex-row">
              <span class="text_19">立即使用</span>
              <img
                class="thumbnail_2"
                referrerpolicy="no-referrer"
                src="./assets/img/7ac23ce3281a986f7e8c37c832eec1d8.png"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="group_38 flex-row justify-between">
        <div class="block_1 flex-col">
          <div class="group_39 flex-row justify-between">
            <div class="group_11 flex-col"><div class="section_4 flex-col"></div></div>
            <div class="text-wrapper_36 flex-col justify-between">
              <span class="text_20">思维模型专家</span>
              <span class="text_21">
                您好，我是精通各种思维模型的专家，请输入您的问题，我会告诉你应该用什么模型，以及这个模型的定义、用法、案例~
              </span>
            </div>
          </div>
          <div class="text-wrapper_37 flex-row"><span class="text_22">用户3337843567711&#64;liuxingliang</span></div>
          <div class="group_40 flex-row">
            <div class="section_5 flex-row">
              <span class="text_23">立即使用</span>
              <img
                class="thumbnail_3"
                referrerpolicy="no-referrer"
                src="./assets/img/0ae2c79f61bc7e34c8469ae74af93001.png"
              />
            </div>
          </div>
        </div>
        <div class="block_2 flex-col">
          <div class="block_13 flex-row justify-between">
            <div class="section_6 flex-col"><div class="block_3 flex-col"></div></div>
            <div class="text-wrapper_38 flex-col justify-between">
              <span class="text_24">思维模型专家</span>
              <span class="text_25">
                您好，我是精通各种思维模型的专家，请输入您的问题，我会告诉你应该用什么模型，以及这个模型的定义、用法、案例~
              </span>
            </div>
          </div>
          <div class="text-wrapper_39 flex-row"><span class="text_26">用户3337843567711&#64;liuxingliang</span></div>
          <div class="block_14 flex-row">
            <div class="section_7 flex-row">
              <span class="text_27">立即使用</span>
              <img
                class="thumbnail_4"
                referrerpolicy="no-referrer"
                src="./assets/img/09d6e3edf0d11c6031733e13270ada2b.png"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="group_41 flex-row justify-between">
        <div class="group_14 flex-col">
          <div class="group_42 flex-row justify-between">
            <div class="section_8 flex-col"><div class="box_7 flex-col"></div></div>
            <div class="text-wrapper_40 flex-col justify-between">
              <span class="text_28">思维模型专家</span>
              <span class="text_29">
                您好，我是精通各种思维模型的专家，请输入您的问题，我会告诉你应该用什么模型，以及这个模型的定义、用法、案例~
              </span>
            </div>
          </div>
          <div class="text-wrapper_41 flex-row"><span class="text_30">用户3337843567711&#64;liuxingliang</span></div>
          <div class="group_43 flex-row">
            <div class="block_4 flex-row">
              <span class="text_31">立即使用</span>
              <img
                class="thumbnail_5"
                referrerpolicy="no-referrer"
                src="./assets/img/5e4ffc504def2637d4b0d3c60b9db7c1.png"
              />
            </div>
          </div>
        </div>
        <div class="group_15 flex-col">
          <div class="block_15 flex-row justify-between">
            <div class="group_16 flex-col"><div class="box_9 flex-col"></div></div>
            <div class="text-wrapper_42 flex-col justify-between">
              <span class="text_32">思维模型专家</span>
              <span class="text_33">
                您好，我是精通各种思维模型的专家，请输入您的问题，我会告诉你应该用什么模型，以及这个模型的定义、用法、案例~
              </span>
            </div>
          </div>
          <div class="text-wrapper_43 flex-row"><span class="text_34">用户3337843567711&#64;liuxingliang</span></div>
          <div class="block_16 flex-row">
            <div class="box_10 flex-row">
              <span class="text_35">立即使用</span>
              <img
                class="thumbnail_6"
                referrerpolicy="no-referrer"
                src="./assets/img/9cb38db250ab595ec4a556776c8e220c.png"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="group_44 flex-row justify-between">
        <div class="group_18 flex-col">
          <div class="group_45 flex-row justify-between">
            <div class="group_20 flex-col"><div class="group_21 flex-col"></div></div>
            <div class="text-wrapper_44 flex-col justify-between">
              <span class="text_36">思维模型专家</span>
              <span class="text_37">
                您好，我是精通各种思维模型的专家，请输入您的问题，我会告诉你应该用什么模型，以及这个模型的定义、用法、案例~
              </span>
            </div>
          </div>
          <div class="text-wrapper_45 flex-row"><span class="text_38">用户3337843567711&#64;liuxingliang</span></div>
          <div class="group_46 flex-row">
            <div class="group_23 flex-row">
              <span class="text_39">立即使用</span>
              <img
                class="thumbnail_7"
                referrerpolicy="no-referrer"
                src="./assets/img/7502b9362172ca00a2900efb92207625.png"
              />
            </div>
          </div>
        </div>
        <div class="group_24 flex-col">
          <div class="section_12 flex-row justify-between">
            <div class="box_11 flex-col"><div class="box_12 flex-col"></div></div>
            <div class="text-wrapper_46 flex-col justify-between">
              <span class="text_40">思维模型专家</span>
              <span class="text_41">
                您好，我是精通各种思维模型的专家，请输入您的问题，我会告诉你应该用什么模型，以及这个模型的定义、用法、案例~
              </span>
            </div>
          </div>
          <div class="text-wrapper_47 flex-row"><span class="text_42">用户3337843567711&#64;liuxingliang</span></div>
          <div class="section_13 flex-row">
            <div class="section_9 flex-row">
              <span class="text_43">立即使用</span>
              <img
                class="thumbnail_8"
                referrerpolicy="no-referrer"
                src="./assets/img/0fd17a6c57e5290d5055adf08e5690dc.png"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="group_47 flex-row justify-between">
        <img
          class="image_5"
          referrerpolicy="no-referrer"
          src="./assets/img/72ed08651144f4611b267245a856d686.png"
        />
        <div class="section_14 flex-col">
          <div class="section_15 flex-row justify-between">
            <div class="block_7 flex-col">
              <div class="group_48 flex-row justify-between">
                <div class="section_10 flex-col"><div class="section_11 flex-col"></div></div>
                <div class="text-wrapper_48 flex-col justify-between">
                  <span class="text_44">思维模型专家</span>
                  <span class="text_45">
                    您好，我是精通各种思维模型的专家，请输入您的问题，我会告诉你应该用什么模型，以及这个模型的定义、用法、案例~
                  </span>
                </div>
              </div>
              <div class="text-wrapper_49 flex-row">
                <span class="text_46">用户3337843567711&#64;liuxingliang</span>
              </div>
              <div class="group_49 flex-row">
                <div class="box_16 flex-row">
                  <span class="text_47">立即使用</span>
                  <img
                    class="thumbnail_9"
                    referrerpolicy="no-referrer"
                    src="./assets/img/810d114fb480f66d85257d335b5e5425.png"
                  />
                </div>
              </div>
            </div>
            <div class="block_8 flex-col">
              <div class="group_50 flex-row justify-between">
                <div class="box_17 flex-col"><div class="block_10 flex-col"></div></div>
                <div class="text-wrapper_50 flex-col justify-between">
                  <span class="text_48">思维模型专家</span>
                  <span class="text_49">
                    您好，我是精通各种思维模型的专家，请输入您的问题，我会告诉你应该用什么模型，以及这个模型的定义、用法、案例~
                  </span>
                </div>
              </div>
              <div class="text-wrapper_51 flex-row">
                <span class="text_50">用户3337843567711&#64;liuxingliang</span>
              </div>
              <div class="group_51 flex-row">
                <div class="group_29 flex-row">
                  <span class="text_51">立即使用</span>
                  <img
                    class="thumbnail_10"
                    referrerpolicy="no-referrer"
                    src="./assets/img/11b08ac43ff795aff17f8b60fdfcf253.png"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="section_16 flex-row justify-between">
            <div class="text-wrapper_24 flex-col"><span class="text_52"></span></div>
            <div class="text-wrapper_25 flex-col"><span class="text_53">1</span></div>
            <div class="text-wrapper_26 flex-col"><span class="text_54">2</span></div>
            <div class="text-wrapper_27 flex-col"><span class="text_55">3</span></div>
            <div class="text-wrapper_28 flex-col"><span class="text_56">...</span></div>
            <div class="text-wrapper_29 flex-col"><span class="text_57">12</span></div>
            <div class="text-wrapper_30 flex-col"><span class="text_58"></span></div>
          </div>
          <div class="group_31 flex-col">
            <div class="image-wrapper_4 flex-col">
              <img
                class="image_6"
                referrerpolicy="no-referrer"
                src="./assets/img/9b00ca62621795a0a97e38565c8435b2.png"
              />
            </div>
            <span class="text_59">开启全新高效的AI设计流程</span>
            <span class="text_60">调-做-改-核-协-出6大工序，轻松获得AI助力</span>
            <div class="block_12 flex-row">
              <span class="text_61">输入手机号即将开始...</span>
              <div class="box_18 flex-row">
                <span class="text_62">免费试用</span>
                <img
                  class="thumbnail_11"
                  referrerpolicy="no-referrer"
                  src="./assets/img/3af0f57e4898c643402bce59de970e69.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="image-wrapper_6 flex-row">
        <img
          class="image_7"
          referrerpolicy="no-referrer"
          src="./assets/img/54d3d61c127e01114e7d317296babff1.png"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.css" />