<template>
  <div class="page flex-col">
    <headerNav :initialSelectedIndex="currentSelectedIndex" @update:selectedIndex="updateSelectedIndex" />

    <div class="content flex-col">
      <div class="group_1 flex-row">
        <div class="box_1 flex-col justify-between">
          <span class="paragraph_1">
            基于BIM/数字孪生
            <br />
            智慧科技行业空间矩阵
          </span>
          <div class="text-wrapper_1 flex-col" @click="linkto('/solution')"><span class="text_1"
              @click="linkto('/solution')">立即咨询</span></div>
        </div>
        <img class="image_1" referrerpolicy="no-referrer" src="./assets/img/115a84d12a8f5be6ee01c4e0306e3c6e.png" />
      </div>

      <div class="group_7 flex-col">
        <div class="text_group1 flex-col">
          <span class="text_2">产品功能与优势</span>
          <span class="text_3">Product&nbsp;Features&nbsp;and&nbsp;Advantages</span>
        </div>
        <div id="shequ" class="group_box flex-row">
          <div class="group_1_shequ_1 flex-col">
            <div class="group_box_shequ flex-col">
              <div class="group_8 flex-row">
                <div class="block_2 flex-col"></div>
                <div class="block_3 flex-col"></div>
                <div class="block_4 flex-col"></div>
              </div>
            </div>
            <div id="shequ" class="group_2 flex-col"></div>
          </div>

          <div class="sheqqu flex-col">
            <span class="text_4">智慧社区</span>
            <span class="text_5">
              智慧社区是现代城市发展的新趋势，它利用先进的信息技术，如物联网、大数据和人工智能，为居民提供安全、便捷、舒适的生活环境。通过智能安防系统保障居民安全，智能停车和能源管理系统提高资源利用率，而在线服务平台则让居民享受到更加便捷的生活服务。智慧社区不仅提升了居住体验，也促进了社区的可持续发展
            </span>

          </div>

        </div>
        <div>
          <img class="image_4" referrerpolicy="no-referrer" src="./assets/img/f217946bc01184ed266342af39e7276c.png" />
        </div>

        <div id="yuanqqu" class="group_box flex-row">
          <div class="group_1_shequ_1 flex-col">
            <div class="group_box_shequ flex-col">
              <div class="group_8 flex-row">
                <div class="block_2 flex-col"></div>
                <div class="block_3 flex-col"></div>
                <div class="block_4 flex-col"></div>
              </div>
            </div>
            <div id="shequ" class="group_3 flex-col"></div>
          </div>

          <div class="sheqqu flex-col">
            <span class="text_4">数智园区</span>
            <span class="text_5">
              数智园区是企业创新与发展的新高地。依托云计算、大数据、人工智能等前沿技术，园区实现了智能化管理与服务。从智能安防到能源优化，从远程办公到供应链管理，数智园区为企业提供了高效、灵活的运营环境。它不仅促进了企业间的协同创新，还为园区内员工带来了更加人性化的工作体验，推动了区域经济的高质量发展
            </span>

          </div>

        </div>
        <div>
          <img class="image_4" referrerpolicy="no-referrer" src="./assets/img/f217946bc01184ed266342af39e7276c.png" />
        </div>


        <div id="jianzao" class="group_box flex-row">
          <div class="group_1_shequ_1 flex-col">
            <div class="group_box_shequ flex-col">
              <div class="group_8 flex-row">
                <div class="block_2 flex-col"></div>
                <div class="block_3 flex-col"></div>
                <div class="block_4 flex-col"></div>
              </div>
            </div>
            <div id="shequ" class="group_4 flex-col"></div>
          </div>

          <div class="sheqqu flex-col">
            <span class="text_4">数智建造</span>
            <span class="text_5">
              数智建造融合了数字化技术与建筑行业，推动了传统建筑向智能化、自动化转型。通过BIM（建筑信息模型）、云计算、物联网等技术，实现了设计、施工、运维全过程的信息化管理。数智建造提高了建筑质量和效率，降低了成本，同时通过数据分析优化资源配置，促进了建筑行业的绿色发展和可持续发展
            </span>

          </div>

        </div>
        <div>
          <img class="image_4" referrerpolicy="no-referrer" src="./assets/img/f217946bc01184ed266342af39e7276c.png" />
        </div>

        <div id="shuili" class="group_box flex-row">
          <div class="group_1_shequ_1 flex-col">
            <div class="group_box_shequ flex-col">
              <div class="group_8 flex-row">
                <div class="block_2 flex-col"></div>
                <div class="block_3 flex-col"></div>
                <div class="block_4 flex-col"></div>
              </div>
            </div>
            <div id="shequ" class="group_5 flex-col"></div>
          </div>

          <div class="sheqqu flex-col">
            <span class="text_4">数智水利</span>
            <span class="text_5">
              数智水利是水利管理现代化的重要方向。通过集成传感器、物联网、大数据分析等技术，实现水资源的实时监测、精准调度和高效利用。智能水网能够预测洪水、干旱等自然灾害，及时响应，减少损失。同时，数智水利还支持水资源的合理分配，优化灌溉系统，促进农业可持续发展，为保障人民生活和经济发展提供坚实支撑
            </span>

          </div>

        </div>
        <div>
          <img class="image_4" referrerpolicy="no-referrer" src="./assets/img/f217946bc01184ed266342af39e7276c.png" />
        </div>


        <div id="shangye" class="group_box flex-row">
          <div class="group_1_shequ_1 flex-col">
            <div class="group_box_shequ flex-col">
              <div class="group_8 flex-row">
                <div class="block_2 flex-col"></div>
                <div class="block_3 flex-col"></div>
                <div class="block_4 flex-col"></div>
              </div>
            </div>
            <div id="shequ" class="group_6 flex-col"></div>
          </div>

          <div class="sheqqu flex-col">
            <span class="text_4">数字商业</span>
            <span class="text_5">
              数字商业正重塑传统商业模式，通过互联网、大数据、云计算等技术，实现商业活动的数字化转型。它打破了时间和空间的限制，让交易更加便捷、高效。个性化推荐、智能客服、在线支付等创新服务，提升了消费者体验。同时，数字商业还通过数据分析优化供应链，降低成本，提高市场响应速度，为企业带来新的增长点

            </span>

          </div>

        </div>




      </div>

      <bottomNav />
    </div>
  </div>
</template>
<script>
import headerNav from '../../../components/headerNav.vue'; // 导入 HeaderNav 组件
import bottomNav from '../../../components/bottomNav.vue'; // 导入 bottomNav 组件


export default {
  components: {
    headerNav,
    bottomNav
  },
  data() {
    return {
      currentSelectedIndex: 2 // 默认选中“新闻动态”
    };
  },
  methods: {
    updateSelectedIndex(index) {
      this.currentSelectedIndex = index; // 更新选中的索引
    },
    linkto(route) {

      this.$router.push(route);
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.css" />